import React from "react";
import { Typography, Container, Box, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PriceCard from "components/Payments/PriceCard";

const PricingSection = () => {
  const theme = useTheme();

  return (
    <Container
      maxWidth="lg"
      sx={{ paddingTop: theme.spacing(8), paddingBottom: theme.spacing(8) }}
      id="pricing"
    >
      <Typography variant="h3" gutterBottom align="center">
        Get started for free.
      </Typography>
      <Typography variant="h6" paragraph align="center" mb={6}>
        A simple real estate pro forma wherever you need it. On site or on the
        go. All for less than the price of a cup of coffee.
      </Typography>
      {/* Your pricing cards will go here */}
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={4}
        justifyContent="center"
      >
        <PriceCard priceType={"monthTrial"} />
        <PriceCard priceType={"yearTrial"} />
      </Stack>
    </Container>
  );
};

export default PricingSection;
