import NumberInputBase from "./NumberInputBase";
const NumberInput = ({
	value,
	setValue,
	label,
	symbol,
	disabled,
	infoTitle,
	infoText,
}) => {
	return (
		<NumberInputBase
			value={value}
			setValue={(value) => setValue(value)}
			label={label}
			currencySymbol={symbol}
			disabled={disabled}
			infoTitle={infoTitle}
			infoText={infoText}
		/>
	);
};
export default NumberInput;
