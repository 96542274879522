import * as auth from "./auth";
import * as user from "./user";
import * as templates from "./templates";
import * as subscriptions from "./subscriptions";
import * as properties from "./properties";

export const api = {
  auth,
  user,
  templates,
  subscriptions,
  properties,
};

export default api;
