import { useContext } from "react";
import { Stack } from "@mui/material";
import PercentageInput from "../Inputs/PercentageInput";
import NumberInput from "../Inputs/NumberInput";
import { CalculateContext } from "context";
import { useUserContext } from "context";

const FinancingTermsInputs = () => {
  const { financingTermsInputs, updateFinancingTerms: update } =
    useContext(CalculateContext);
  const { limitAccess } = useUserContext();

  return (
    <Stack>
      <PercentageInput
        label={"Down Payment Percentage"}
        value={financingTermsInputs.downPaymentPercentage}
        setValue={(value) => update("downPaymentPercentage", value)}
        disabled={limitAccess}
      />
      <PercentageInput
        label={"Annual Interest Rate"}
        value={financingTermsInputs.loanInterestRate}
        setValue={(value) => update("loanInterestRate", value)}
        disabled={limitAccess}
      />
      <NumberInput
        label={"Amortization Period"}
        value={financingTermsInputs.loanAmortizationPeriodInYears}
        setValue={(value) => update("loanAmortizationPeriodInYears", value)}
        symbol={"years"}
        disabled={limitAccess}
        infoTitle="Amortization Period"
        infoText="The amortization period is the length of time it takes to pay off a loan. The amortization period is not always the same as the loan term."
      />
    </Stack>
  );
};

export default FinancingTermsInputs;
