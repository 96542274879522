import { useContext } from "react";
import { Stack } from "@mui/material";
import { CalculateContext } from "context";
import ToggleInput from "../Inputs/ToggleInput";
import PercentageInput from "../Inputs/PercentageInput";
import { useUserContext } from "context";

const OperatingExpensesInputs = () => {
  const { operatingExpensesInputs, updateOperatingExpenses: update } =
    useContext(CalculateContext);
  const { limitAccess } = useUserContext();

  return (
    <Stack>
      <PercentageInput
        label={"Vacancy Rate"}
        value={operatingExpensesInputs.vacancyRate}
        setValue={(value) => update("vacancyRate", value)}
        disabled={limitAccess}
        infoTitle="Vacancy Rate"
        infoText="The vacancy rate is the percentage of all available units in a property that are vacant or unoccupied at any particular time. The vacancy rate is typically 5-10% depending on asset type."
      />

      <ToggleInput
        label={"Management Fee"}
        toggleKey="managementFeeIsPercentage"
        amountKey="managementFeeAmount"
        percentageKey="managementFeePercentage"
        disabled={limitAccess}
        infoTitle="Management Fee"
        infoText="Enter either a fixed dollar amount or a percentage of the annual gross rent that is paid to the property manager for managing the property."
        data={operatingExpensesInputs}
        update={update}
      />
      <ToggleInput
        label={"Maintenance Fee"}
        toggleKey="maintenanceFeeIsPercentage"
        amountKey="maintenanceFeeAmount"
        percentageKey="maintenanceFeePercentage"
        disabled={limitAccess}
        infoTitle="Maintenance Fee"
        infoText="Enter either a fixed dollar amount or a percentage of the annual gross rent that is paid to the property manager for maintaining the property."
        data={operatingExpensesInputs}
        update={update}
      />

      <ToggleInput
        label={"Insurance"}
        toggleKey="insuranceIsPercentage"
        amountKey="insuranceAmount"
        percentageKey="insurancePercentage"
        disabled={limitAccess}
        infoTitle="Insurance"
        infoText="Enter either a fixed dollar amount or a percentage of the annual gross rent for property insurance."
        data={operatingExpensesInputs}
        update={update}
      />

      <ToggleInput
        label={"Property Tax"}
        toggleKey="propertyTaxIsPercentage"
        amountKey="propertyTaxAmount"
        percentageKey="propertyTaxPercentage"
        disabled={limitAccess}
        infoTitle="Property Tax"
        infoText="Enter either a fixed dollar amount or a percentage of the purchase price for the annual property taxes."
        data={operatingExpensesInputs}
        update={update}
      />

      <ToggleInput
        label={"Utilities"}
        toggleKey="utilitiesIsPercentage"
        amountKey="utilitiesAmount"
        percentageKey="utilitiesPercentage"
        disabled={limitAccess}
        infoTitle="Utilities"
        infoText="Enter either a fixed dollar amount or a percentage of the annual gross rent for utilities paid each year."
        data={operatingExpensesInputs}
        update={update}
      />
    </Stack>
  );
};

export default OperatingExpensesInputs;
