import NumberInputBase from "./NumberInputBase";

const PercentageInput = ({
	value,
	setValue,
	label,
	handleToggle,
	isToggled,
	disabled,
	infoTitle,
	infoText,
}) => {
	return (
		<NumberInputBase
			value={value * 100}
			setValue={(value) => setValue(value / 100)}
			label={label}
			currencySymbol={"%"}
			handleToggle={handleToggle}
			isToggled={isToggled}
			disabled={disabled}
			infoTitle={infoTitle}
			infoText={infoText}
		/>
	);
};
export default PercentageInput;
