import { useState } from "react";
import {
  Stack,
  Box,
  MenuItem,
  FormControl,
  Select,
  Typography,
  // Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import OptionsMenu from "./OptionsMenu";
import Modal from "components/Modal";
import { useUserContext } from "context";

const OptionsBar = ({
  title,
  items,
  handleSelectItem,
  createFormTitle,
  createFormButtonText,
  createForm,
  createMutation,
  createData,
  updateMutation,
  updateData,
  deleteMutation,
  menuIdentifier,
}) => {
  const { limitAccess } = useUserContext();
  const [selectedItemId, setSelectedItemId] = useState("default");
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    const itemId = event.target.value;
    setSelectedItemId(itemId);
    let item = { id: itemId };

    if (itemId !== "default") {
      item = items?.find((item) => item.id === itemId);
    }

    handleSelectItem(item);
  };

  const handleCreate = async (data) => {
    createMutation.mutate(data, {
      onSuccess: (data) => {
        setSelectedItemId(data.id);
        setOpen(false);
      },
    });
  };

  const handleUpdate = async (data) => {
    return new Promise((resolve, reject) => {
      let item = items?.find((item) => item.id === selectedItemId);
      updateMutation.mutate(
        {
          ...item,
          ...data,
        },
        {
          onSuccess: (data) => {
            setSelectedItemId(data.id);
            setOpen(false);
            resolve(data);
          },
          onError: (error) => {
            reject(error);
          },
        }
      );
    });
  };

  const handleDelete = async () => {
    return new Promise((resolve, reject) => {
      deleteMutation.mutate(selectedItemId, {
        onSuccess: (data) => {
          setSelectedItemId("default");
          setOpen(false);
        },
      });
    });
  };

  if (limitAccess) return null;

  return (
    <>
      <Typography variant="h6" component="h2">
        {title}
      </Typography>
      <Stack direction={"row"} justifyContent={"space-between"} pb={1} pt={1}>
        <Box sx={{ flex: 1 }}>
          <FormControl variant="standard" fullWidth>
            <Select value={selectedItemId} onChange={handleChange} fullWidth>
              <MenuItem value={"default"}>Default</MenuItem>
              {items?.map((template) => (
                <MenuItem key={template.id} value={template.id}>
                  {template.name || "NA"}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <OptionsMenu
            handleCreate={() => setOpen(true)}
            handleUpdate={() => handleUpdate(updateData)}
            handleDelete={handleDelete}
            isDefault={selectedItemId === "default"}
            isLoadingCreate={createMutation.isLoading}
            isLoadingUpdate={updateMutation.isLoading}
            isLoadingDelete={deleteMutation.isLoading}
            menuIdentifier={menuIdentifier}
          />
        </Box>
      </Stack>
      <Modal open={open} handleClose={() => setOpen(false)}>
        <Stack minWidth={300}>
          <Typography variant="h6" component="h2">
            {createFormTitle}
          </Typography>
          {createForm}
          <Box mt={2} />
          <LoadingButton
            variant="contained"
            onClick={() => handleCreate(createData)}
            loading={createMutation.isLoading}
          >
            {createFormButtonText}
          </LoadingButton>
        </Stack>
      </Modal>
    </>
  );
};

export default OptionsBar;
