export const setSession = (sessionData) => {
	if (sessionData) {
		const { AccessToken, RefreshToken, TokenType } = sessionData;
		localStorage.setItem("accessToken", AccessToken);
		localStorage.setItem("refresh_token", RefreshToken);
		localStorage.setItem("tokenType", TokenType ?? "Bearer");
	} else {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("refresh_token");
		localStorage.removeItem("tokenType");
		localStorage.removeItem("isAdmin");
	}
};
