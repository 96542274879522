import {
  Card,
  Box,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import MainResultsDisplay from "components/MainResultsDisplay";
import DCFResultsDisplay from "components/DCFResultsDisplay";

import CalulatorInput from "components/InputForms/CalculatorInput";
import DCFInputs from "components/InputForms/DCFInputs";
import AcquisitionCostInputs from "components/InputForms/AcquisitionCostInputs";
import FinancingTermsInputs from "components/InputForms/FinancingTermsInputs";
import OperatingExpensesInputs from "components/InputForms/OperatingExpensesInputs";

import TemplateOptions from "components/OptionsBars/TemplateOptions";
import PropertyOptions from "components/OptionsBars/PropertyOptions";

const CommercialCalculator = () => {
  return (
    <Stack direction={{ xs: "column", md: "row" }} pt={3}>
      <Box pt={3}>
        <PropertyOptions />
        <Form>
          <CalulatorInput />
        </Form>
        <TemplateOptions />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "bold" }}>
              Acquisition Costs
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AcquisitionCostInputs />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "bold" }}>Financing Terms</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FinancingTermsInputs />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "bold" }}>
              Annual Operating Expenses
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <OperatingExpensesInputs />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "bold" }}>
              Cash Flow Analysis
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DCFInputs />
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ flex: 1 }} pt={3} ml={{ xs: 0, md: 3 }}>
        <MainResultsDisplay />
        <DCFResultsDisplay />
      </Box>
    </Stack>
  );
};

export default CommercialCalculator;

const Form = ({ children }) => {
  return (
    <Card sx={{ mb: 3 }}>
      <Box p={2}>{children}</Box>
    </Card>
  );
};
