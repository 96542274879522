import { useState, useContext } from "react";
import { Typography, Box, Stack, Card, Button } from "@mui/material";
import Modal from "components/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation } from "react-query";
import api from "api";
import { UserContext } from "context";

const Subscription = ({ sub }) => {
  const [open, setOpen] = useState(false);
  const { refetch } = useContext(UserContext);

  const { mutate, isLoading } = useMutation(api.subscriptions.cancel, {
    onSuccess: () => {
      console.log("SUCCESSFULLY CANCELLED SUBSCRIPTION");
      refetch();
      setOpen(false);
    },
    onError: (error) => {
      console.log("ERROR CANCELLING SUBSCRIPTION: ", error);
    },
  });

  const { mutate: renew, isLoading: renewLoading } = useMutation(
    api.subscriptions.renew,
    {
      onSuccess: () => {
        console.log("SUCCESSFULLY RENEWED SUBSCRIPTION");
        refetch();
        setOpen(false);
      },
      onError: (error) => {
        console.log("ERROR RENEWING SUBSCRIPTION: ", error);
      },
    }
  );

  const handleRenew = () => {
    console.log("RENEWING SUBSCRIPTION");
    renew(sub.id);
  };

  const handleConfirm = () => {
    console.log("CONFIRMED CANCEL SUBSCRIPTION");
    mutate(sub.id);
  };

  return (
    <>
      <Box mb={1.5}>
        <Card>
          <Stack direction={"row"} p={1.5}>
            <Box sx={{ flex: 1 }}>
              <Typography variant={"h6"}>
                Easy Pro Form - {sub.plan.interval}
              </Typography>
              <Typography
                variant={"body1"}
                color={sub.cancel_at_period_end ? "error" : null}
              >
                {parseSubscriptionDescription(sub)}
              </Typography>
            </Box>
            <Box>
              {sub.cancel_at_period_end ? (
                <LoadingButton
                  variant="contained"
                  onClick={handleRenew}
                  loading={renewLoading}
                >
                  Renew
                </LoadingButton>
              ) : (
                <Button variant="outlined" onClick={() => setOpen(true)}>
                  Cancel
                </Button>
              )}
            </Box>
          </Stack>
        </Card>
      </Box>
      <Modal
        open={open}
        handleClose={() => setOpen(false)}
        title={"Are you sure you want to cancel?"}
        body={`Access to your templates will end on ${new Date(
          sub.current_period_end * 1000
        ).toLocaleDateString()}.`}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Box mr={1}>
            <Button variant="contained" onClick={() => setOpen(false)}>
              Nevermind
            </Button>
          </Box>

          <LoadingButton
            loading={isLoading}
            variant="outlined"
            onClick={handleConfirm}
          >
            Yes, cancel
          </LoadingButton>
        </Box>
      </Modal>
    </>
  );
};

export default Subscription;

const parseSubscriptionDescription = (sub) => {
  if (sub.cancel_at_period_end) {
    return `Ending on ${new Date(sub.cancel_at * 1000).toLocaleDateString()}`;
  } else if (sub.status === "active") {
    return `Renewing on ${new Date(
      sub.current_period_end * 1000
    ).toLocaleDateString()}`;
  } else if (sub.status === "trialing") {
    return `Trial ends on ${new Date(
      sub.trial_end * 1000
    ).toLocaleDateString()}`;
  }
};
