// import { useContext } from "react";
import PercentageInput from "./PercentageInput"; // Assume you have PercentageInput component
import CurrencyInput from "./CurrencyInput";
// import { CalculateContext } from "context"; // Assume you have CurrencyInput component

const ToggleInput = ({
  label,
  toggleKey,
  amountKey,
  percentageKey,
  disabled,
  infoTitle,
  infoText,
  data,
  update,
}) => {
  //   const { costInputs, updateCost: update } = useContext(CalculateContext);

  const handleToggleChange = (event) => {
    update(toggleKey, event.target.checked);
  };

  return (
    <>
      {data[toggleKey] ? (
        <PercentageInput
          value={data[percentageKey]}
          setValue={(value) => update(percentageKey, value)}
          label={label}
          handleToggle={handleToggleChange}
          isToggled={data[toggleKey]}
          disabled={disabled}
          infoTitle={infoTitle}
          infoText={infoText}
        />
      ) : (
        <CurrencyInput
          value={data[amountKey]}
          setValue={(value) => update(amountKey, value)}
          label={label}
          handleToggle={handleToggleChange}
          isToggled={data[toggleKey]}
          disabled={disabled}
          infoTitle={infoTitle}
          infoText={infoText}
        />
      )}
    </>
  );
};

export default ToggleInput;
