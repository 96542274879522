import { Typography, Container, Grid, Box } from "@mui/material";

const FeaturesSection = () => {
  const features = [
    {
      title: "Just the Numbers.",
      description:
        "See only what matters. Update the numbers wherever you are and get the metrics instantly. Don’t get lost in (or pay for) unnecessary app features, pretty pictures, or unreliable 3rd party property data. Easy Pro Forma is built for the serious investor and their brokers.",
    },
    {
      title: "Save Properties for Later.",
      description:
        "Easily and dynamically adjust transaction costs, financing terms, operating expenses, and DCF inputs to your usual assumptions and save them for later. On the next property, just select your preferred set of saved assumptions and you're ready to go. Save property inputs to continue tweaking the numbers as new information or ideas arise.",
    },
    {
      title: "Discounted Cash Flow Analysis.",
      description:
        "Run a discounted cash flow analysis for your anticipated holding period. See the IRR, NPV and numbers from your eventual sale of the property, as well as the breakdown for each year during your holding period.",
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ paddingTop: 8, paddingBottom: 8 }}>
      <Typography variant="h3" gutterBottom align="center" mb={6}>
        All the features you need. None you don't.
      </Typography>
      <Grid container spacing={3}>
        {features.map((feature, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Typography variant="h5" gutterBottom textAlign={"center"}>
              {feature.title}
            </Typography>
            <Typography variant="body1" paragraph textAlign={"center"}>
              {feature.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default FeaturesSection;
