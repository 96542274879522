import { Card, Box } from "@mui/material";

const buttonIds = {
  monthTrial: process.env.REACT_APP_STRIPE_BUY_BUTTON_ID_MONTH_TRIAL,
  yearTrial: process.env.REACT_APP_STRIPE_BUY_BUTTON_ID_YEAR_TRIAL,
  month: process.env.REACT_APP_STRIPE_BUY_BUTTON_ID_MONTH,
  year: process.env.REACT_APP_STRIPE_BUY_BUTTON_ID_YEAR,
};

const PriceCard = ({ priceType, customerEmail }) => {
  const stripeButtonId = buttonIds[priceType];
  if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || !stripeButtonId)
    return null;

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", mb: { xs: 2, md: 0 } }}
    >
      <Card>
        <stripe-buy-button
          buy-button-id={stripeButtonId}
          publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
          customer-email={customerEmail}
        />
      </Card>
    </Box>
  );
};

export default PriceCard;
