import { unauthedAxios, authedAxios } from "./axios";

export const login = (data) => {
	return unauthedAxios.post("/auth/login", data);
};

export const logout = (data) => {
	return authedAxios.post("/auth/logout", data);
};

export const forgotPassword = (data) => {
	return unauthedAxios.post("/auth/forgot-password", data);
};

export const resetPassword = (data) => {
	return unauthedAxios.post("/auth/reset-password", data);
};

export const refresh = (data) => {
	return unauthedAxios.post("/auth/refresh", data);
};
