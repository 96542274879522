export const PROPERTY_INPUTS_DEFAULTS = {
  purchasePrice: 0,
  monthlyRent: 0,
  squareFootage: 0,
};

export const ACQUISITION_COST_INPUTS_DEFAULTS = {
  titleFeeIsPercentage: false,
  titleFeesPercentage: 0.01,
  titleFeesAmount: 5000,

  environmentalTestingCostIsPercentage: false,
  environmentalTestingCostPercentage: 0.01,
  environmentalTestingCostAmount: 3500,

  dueDiligenceCostIsPercentage: false,
  dueDiligenceCostPercentage: 0.01,
  dueDiligenceCostAmount: 1500,

  miscellaneousCostsIsPercentage: true,
  miscellaneousCostsPercentage: 0.005,
  miscellaneousCostsAmount: 7500, // can be percentage of purchase price

  bankFinancingFeesIsPercentage: true,
  bankFinancingFeesPercentage: 0.01,
  bankFinancingFeesAmount: 12000, // can be percentage of loan amount
};

export const FINANCING_TERMS_INPUTS_DEFAULTS = {
  downPaymentPercentage: 0.2,
  loanInterestRate: 0.06,
  loanAmortizationPeriodInYears: 25,
};

export const OPERATING_EXPENSES_INPUTS_DEFAULTS = {
  vacancyRate: 0.05,

  managementFeeIsPercentage: true,
  managementFeePercentage: 0.05,
  managementFeeAmount: 8500, // can be percentage of rent

  propertyTaxIsPercentage: true,
  propertyTaxPercentage: 0.02,
  propertyTaxAmount: 35000, // can be percentage of purchase price

  insuranceIsPercentage: true,
  insurancePercentage: 0.005,
  insuranceAmount: 7500, // can be percentage of purchase price

  utilitiesIsPercentage: true,
  utilitiesPercentage: 0.15,
  utilitiesAmount: 40000, // can be percentage of rent

  maintenanceFeeIsPercentage: true,
  maintenanceFeePercentage: 0.05,
  maintenanceFeeAmount: 8500, // can be percentage of rent
};

export const DCF_INPUTS_DEFAULTS = {
  calculateExtraYear: true,
  numberOfYears: 5,
  annualRentIncreaseRate: 0.03,
  annualOperatingExpenseIncreaseRate: 0.03,
  landValuePercentage: 0.25,
  propertyDepreciationPeriodInYears: 39,
  incomeTaxRate: 0.35,
  capitalGainsTaxRate: 0.15,
  endingCapitalizationRate: 0.07,
  costOfSalePercentage: 0.06,
  afterTaxRequiredRateOfReturn: 0.12,
};

export const CASHFLOW_PARAMETER_NAME_OVERRIDES = {
  annualGrossRent: "Effective Gross Rent",
  annualNetOperatingIncome: "Net Operating Income",
  annualOperatingExpenses: "Operating Expenses",
  remainingLoan: "Remaining Loan Balance",
  loanPayment: "Total Loan Payment",
};
