import { useContext } from "react";
import MetricDisplay from "./MetricDisplay";
import { CalculateContext } from "context";
import { calculateOutput, dcfRefactor } from "calculations";
import DisplayCard from "./DisplayCard";
import { Box, Typography } from "@mui/material";

import { camelCaseToTitleCase } from "utils";
import { CASHFLOW_PARAMETER_NAME_OVERRIDES } from "constants";

const headerStyle = {
  textAlign: "center",
}

const DCFResultsDisplay = () => {
  const { combinedInputs, dcfInputs } = useContext(CalculateContext);
  const propertyOutputs = calculateOutput(combinedInputs);
  const outputdcf = dcfRefactor(combinedInputs, propertyOutputs, dcfInputs);

  return (
    <>
      <DisplayCard>
        <h2 style={headerStyle}>Discounted Cash Flow Analysis</h2>
        <MetricDisplay
          title="Net Present Value (NPV)"
          value={outputdcf.finalNetPresentValue}
          infoTitle="NPV"
          infoText="The value, in today's dollars, of all of the below future cash flows from the property."
          colorizeValue
        />
        <MetricDisplay
          title="Internal Rate of Return (IRR)"
          value={outputdcf.finalInternalRateOfReturn}
          percent
          infoTitle="IRR"
          infoText="The expected compound annual rate of return over the holding period. Or, the discount rate at which NPV equals zero."
          colorizeValue
        />
      </DisplayCard>
      <DisplayCard>
        <h2 style={headerStyle}>Exit Sale</h2>
        <MetricDisplay
          title="Initial Cash Investment"
          value={outputdcf.initialInvestmentAmount}
        />
        <MetricDisplay
          title="Exit Sale Price"
          value={outputdcf.propertySalesPrice}
          infoTitle="Exit Sale Price"
          infoText={["Calculated based on the NOI of the year following the last full year of cash flow (see Calculate Extra Year under Cash Flow Analysis above).", 
          "Ex: if the holding period is 5 years, and Calculate Extra Year is selected, then the Exit Sale Price is calculated based on the estimated NOI in year 6. If Calculate Extra Year is not selected, the Exit Sale Price is calculated based on the Year 5 NOI."]}
        />
        <MetricDisplay
          title="Closing Costs"
          value={outputdcf.propertySaleCost}
        />
        <MetricDisplay
          title="Gain On Sale"
          value={outputdcf.gainOnPropertySale}
        />
        <MetricDisplay
          title="Capital Gains Tax"
          value={outputdcf.capitalGainsTaxAmount}
        />
        <MetricDisplay
          title="After Tax Cash From Sale"
          value={outputdcf.afterTaxReversionValue}
          colorizeValue
        />
      </DisplayCard>
      <DisplayCard>
        {/* <DCFChart data={outputdcf.cashFlows} /> */}
        <h2 style={headerStyle}>Cash Flows</h2>
        {outputdcf.annualCashFlows.map((year) => {
          return (
            <CashFlowDisplay key={year.year} data={year} year={year.year} />
          );
        })}
      </DisplayCard>
    </>
  );
};

export default DCFResultsDisplay;

const CashFlowDisplay = ({ data, year }) => {
  return (
    <Box padding={1} backgroundColor={"rgba(0,0,0,0.1)"} mb={2}>
      <Typography style={headerStyle} variant="h6" pb={1}>
        Year {year}
      </Typography>
      {Object.keys(data)
        .filter((key) => key !== "year")
        .map((key) => {
          return (
            <MetricDisplay
              key={key}
              title={
                CASHFLOW_PARAMETER_NAME_OVERRIDES[key] ||
                camelCaseToTitleCase(key)
              }
              value={data[key]}
              small
            />
          );
        })}
    </Box>
  );
};
