import { Card, Box, Typography } from "@mui/material";

const DisplayCard = ({ title, children }) => {
	return (
		<Card sx={{ mb: 3 }}>
			<Box p={3}>
				<Typography variant="h5">{title}</Typography>
				<Box>{children}</Box>
			</Box>
		</Card>
	);
};

export default DisplayCard;
