import ResponsiveAppBar from "./components/ResponsiveAppBar";
import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import AuthGuard from "auth/AuthGuard";
import UserWrapper from "UserWrapper";
const Layout = () => {
	return (
		<AuthGuard>
			<UserWrapper>
				<ResponsiveAppBar />
				<Container>
					<Outlet />
				</Container>
			</UserWrapper>
		</AuthGuard>
	);
};
export default Layout;
