import React from "react";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import "./inputStyle.css";
import { PropTypes } from "prop-types";
import {
	Box,
	FormControlLabel,
	Switch,
	Typography,
	Stack,
} from "@mui/material";
import InfoModal from "components/InfoModal";

const NumberInputRaw = ({
	value,
	setValue,
	label,
	currencySymbol,
	outputFormat,
	handleToggle,
	isToggled,
	disabled,
	infoTitle,
	infoText,
}) => {
	// const [isToggled, setIsToggled] = React.useState(false);

	// const handleToggle = (event) => {
	// 	setIsToggled(event.target.checked);
	// };

	return (
		<Box pb={2}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Stack
					direction="row"
					sx={{
						justifyContent: "flex-start",
						alignItems: "flex-start",
					}}
				>
					<label>{label}</label>
					<InfoModal title={infoTitle} text={infoText} />
				</Stack>

				<Box
					sx={{
						display: "flex",
						alignItems: "center",
					}}
				>
					{!!handleToggle && (
						<>
							<Typography sx={{ mr: 0.5 }}>$</Typography>
							<FormControlLabel
								control={
									<Switch
										checked={isToggled}
										onChange={handleToggle}
										name="toggleSwitch"
										size="small"
										disabled={disabled}
									/>
								}
								sx={{ margin: 0 }}
							/>
							<Typography sx={{ ml: 0.5 }}>%</Typography>{" "}
						</>
					)}
				</Box>
			</Box>
			<CurrencyTextField
				variant="standard"
				value={value}
				currencySymbol={currencySymbol}
				outputFormat={outputFormat}
				decimalCharacter="."
				digitGroupSeparator=","
				onChange={(event, value) => setValue(value)}
				inputProps={{ className: "align-right" }}
				fullWidth
				disabled={disabled}
			/>
		</Box>
	);
};

NumberInputRaw.propTypes = {
	value: PropTypes.number.isRequired,
	setValue: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
	currencySymbol: PropTypes.string,
};

NumberInputRaw.defaultProps = {
	currencySymbol: "$",
	outputFormat: "number",
};

export default NumberInputRaw;
