import { useContext } from "react";
import { Stack, Switch, Box } from "@mui/material";
import NumberInput from "../Inputs/NumberInput";
import PercentageInput from "../Inputs/PercentageInput";
import { CalculateContext } from "context";
import { useUserContext } from "context";
import InfoModal from "../InfoModal";

const DCFInputs = () => {
  const { dcfInputs, updateDcf: update } = useContext(CalculateContext);
  const { limitAccess } = useUserContext();

  const handleToggleExtraYear = () => {
    update("calculateExtraYear", !dcfInputs.calculateExtraYear);
  };

  return (
    <Stack>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pb={2}
      >
        <Stack direction="row">
          <Box component="span">Calculate Extra Year</Box>{" "}
          <InfoModal 
            title="Calculate Extra Year" 
            text={["This determines how the DCF and Exit Sale metrics are calculated. If selected, then it is assumed the exit sale occurs in the beginning of the year following the holding period. If not selected, then it is assumed the exit sale occurs in the final year of the holding period.",
            "Ex: On a 5 year holding period, if selected, the exit sale occurs at the beginning of Year 6 and 5 full years of cash flow are accounted for. If not selected, the exit sale occurs in year 5 and only 4 full years of cash flow are accounted for."]}
          />
        </Stack>
        <Switch
          checked={dcfInputs.calculateExtraYear}
          onChange={handleToggleExtraYear}
          name="calculateExtraYear"
          color="primary"
          size="small"
          disabled={limitAccess}
        />
      </Box>
      <NumberInput
        label={"Holding Period"}
        value={dcfInputs.numberOfYears}
        setValue={(value) => update("numberOfYears", value)}
        symbol={"years"}
        disabled={limitAccess}
      />
      <PercentageInput
        label={"Annual Rent Increase Rate"}
        value={dcfInputs.annualRentIncreaseRate}
        setValue={(value) => update("annualRentIncreaseRate", value)}
        disabled={limitAccess}
      />
      <PercentageInput
        label={"Annual Operating Expense Increase Rate"}
        value={dcfInputs.annualOperatingExpenseIncreaseRate}
        setValue={(value) =>
          update("annualOperatingExpenseIncreaseRate", value)
        }
        disabled={limitAccess}
      />
      <PercentageInput
        label={"Land Value Percentage"}
        value={dcfInputs.landValuePercentage}
        setValue={(value) => update("landValuePercentage", value)}
        disabled={limitAccess}
        infoTitle="Land Value Percentage"
        infoText="A percentage of the total Purchase Price. Indicates the estimate of the value of just the land excluding the value of any buildings or other improvements."
      />
      <NumberInput
        label={"Property Depreciation Period"}
        value={dcfInputs.propertyDepreciationPeriodInYears}
        setValue={(value) => update("propertyDepreciationPeriodInYears", value)}
        symbol={"years"}
        disabled={limitAccess}
      />
      <PercentageInput
        label={"Marginal Income Tax Rate"}
        value={dcfInputs.incomeTaxRate}
        setValue={(value) => update("incomeTaxRate", value)}
        disabled={limitAccess}
      />
      <PercentageInput
        label={"Capital Gains Tax Rate"}
        value={dcfInputs.capitalGainsTaxRate}
        setValue={(value) => update("capitalGainsTaxRate", value)}
        disabled={limitAccess}
      />
      <PercentageInput
        label={"Exit Capitalization Rate"}
        value={dcfInputs.endingCapitalizationRate}
        setValue={(value) => update("endingCapitalizationRate", value)}
        disabled={limitAccess}
      />
      <PercentageInput
        label={"Cost of Exit Sale"}
        value={dcfInputs.costOfSalePercentage}
        setValue={(value) => update("costOfSalePercentage", value)}
        disabled={limitAccess}
        infoTitle="Cost of Exit Sale"
        infoText="A percentage of the exit sale price at the end of the holding period for closing costs, brokers fees, etc."
      />
      <PercentageInput
        label={"After Tax Required Rate of Return"}
        value={dcfInputs.afterTaxRequiredRateOfReturn}
        setValue={(value) => update("afterTaxRequiredRateOfReturn", value)}
        disabled={limitAccess}
      />
    </Stack>
  );
};

export default DCFInputs;
