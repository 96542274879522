import NumberInputBase from "./NumberInputBase";
const CurrencyInput = ({
	value,
	setValue,
	label,
	handleToggle,
	isToggled,
	disabled,
	infoTitle,
	infoText,
}) => {
	return (
		<NumberInputBase
			value={value}
			setValue={(value) => setValue(value)}
			label={label}
			handleToggle={handleToggle}
			isToggled={isToggled}
			disabled={disabled}
			infoTitle={infoTitle}
			infoText={infoText}
		/>
	);
};
export default CurrencyInput;
