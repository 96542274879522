import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
// form
import { useForm } from "react-hook-form";
// @mui
import { Link, Stack, Alert, Box, InputLabel, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { useAuthContext } from "../../auth/useAuthContext";
import FormProvider, { RHFTextField } from "../hook-form";

// ----------------------------------------------------------------------

const AuthLoginForm = ({ onRegister, onForgotPassword }) => {
	const navigate = useNavigate();
	const [newPasswordRequired, setNewPasswordRequired] = useState(false);

	const { login } = useAuthContext();

	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.email("Email must be a valid email address")
			.required("Email is required"),
		password: Yup.string().required("Password is required"),
	});

	const defaultValues = {
		email: "",
		password: "",
	};

	const methods = useForm({
		resolver: yupResolver(LoginSchema),
		defaultValues,
	});

	const {
		reset,
		watch,
		setError,
		handleSubmit,
		formState: { errors, isSubmitting, isSubmitSuccessful },
	} = methods;

	const { email } = watch();

	const onSubmit = async (data) => {
		try {
			await login(data);
			navigate("/home");
		} catch (error) {
			if (error.response.status === 422) {
				setNewPasswordRequired(true);
				setError("afterSubmit", {
					...error,
					message: "You need to create a new password.",
				});

				return;
			}

			const message = error.response?.data?.message || error.message;

			reset();

			setError("afterSubmit", {
				...error,
				message,
			});
		}
	};

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Box sx={{ px: 2, py: 3 }}>
				<Stack gap={3}>
					<Typography variant="body1">Log into Easy Pro Forma</Typography>

					{!!errors.afterSubmit && (
						<Alert severity="error">{errors.afterSubmit.message}</Alert>
					)}

					<Box>
						<InputLabel sx={{ mb: 0.5 }}>Email</InputLabel>
						<RHFTextField
							name="email"
							placeholder="name@domain.com"
							disabled={newPasswordRequired}
						/>
					</Box>

					<Box>
						<InputLabel sx={{ mb: 0.5 }}>Password</InputLabel>
						<RHFTextField
							name="password"
							placeholder="Password"
							type="password"
							disabled={newPasswordRequired}
						/>
					</Box>

					{newPasswordRequired && (
						<Box>
							<InputLabel sx={{ mb: 0.5 }}>New Password</InputLabel>
							<RHFTextField
								name="newPassword"
								placeholder="New Password"
								type="password"
							/>
						</Box>
					)}

					<Stack alignItems="flex-end">
						<Link onClick={onForgotPassword} variant="body1" underline="none">
							Forgot password?
						</Link>
					</Stack>

					<LoadingButton
						sx={{ textTransform: "capitalize" }}
						size="large"
						fullWidth
						type="submit"
						variant="contained"
						loading={isSubmitSuccessful || isSubmitting}
					>
						Log In
					</LoadingButton>

					{/* <Stack
						direction="row"
						gap={1}
						justifyContent="center"
						alignItems="center"
					>
						<Typography variant="body1" color="text.secondary">
							Need an account?
						</Typography>

						<Link onClick={onRegister} variant="body1" underline="none">
							Sign Up
						</Link>
					</Stack> */}
				</Stack>
			</Box>
		</FormProvider>
	);
};

export default AuthLoginForm;
