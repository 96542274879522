import { useContext } from "react";
import { Stack } from "@mui/material";
import CurrencyInput from "../Inputs/CurrencyInput";
import { CalculateContext } from "context";
import ToggleInput from "../Inputs/ToggleInput";
import { useUserContext } from "context";

const AcquisitionCostInputs = () => {
  const { acquisitionCostInputs, updateAcquisitionCost: update } =
    useContext(CalculateContext);
  const { limitAccess } = useUserContext();

  return (
    <Stack>
      <ToggleInput
        label={"Title & Survey Fees"}
        toggleKey="titleFeeIsPercentage"
        amountKey="titleFeesAmount"
        percentageKey="titleFeesPercentage"
        disabled={limitAccess}
        data={acquisitionCostInputs}
        update={update}
        infoTitle="Title Fees"
        infoText={[
          "Amounts charged by a surveyor or by a title company in relation to a title policy or closing.",
          "Enter either a fixed dollar amount or a percentage of the purchase price.",
      ]}
      />

      <ToggleInput
        label={"Environmental Testing"}
        toggleKey="environmentalTestingCostIsPercentage"
        amountKey="environmentalTestingCostAmount"
        percentageKey="environmentalTestingCostPercentage"
        disabled={limitAccess}
        data={acquisitionCostInputs}
        update={update}
        infoTitle="Environmental Testing"
        infoText={[
          "Phase I ESA, Phase II, etc.",
          "Enter either a fixed dollar amount or a percentage of the purchase price.",
      ]}
      />
      <ToggleInput
        label={"Other Due Diligence"}
        toggleKey="dueDiligenceCostIsPercentage"
        amountKey="dueDiligenceCostAmount"
        percentageKey="dueDiligenceCostPercentage"
        disabled={limitAccess}
        data={acquisitionCostInputs}
        update={update}
        infoTitle="Due Diligence"
        infoText={[
          "Any other costs associated with the inspection of the property.",
          "Enter either a fixed dollar amount or a percentage of the purchase price.",
      ]}
      />
      <ToggleInput
        label={"Bank Fees"}
        toggleKey="bankFinancingFeesIsPercentage"
        amountKey="bankFinancingFeesAmount"
        percentageKey="bankFinancingFeesPercentage"
        disabled={limitAccess}
        infoTitle="Bank Fees"
        infoText={[
          "Fees charged by the bank in connection with the mortgage loan.", 
          "Enter either a fixed dollar amount or a percentage of the loan amount."]}
        data={acquisitionCostInputs}
        update={update}
      />
      <ToggleInput
        label={"Miscellaneous"}
        toggleKey="miscellaneousCostsIsPercentage"
        amountKey="miscellaneousCostsAmount"
        percentageKey="miscellaneousCostsPercentage"
        disabled={limitAccess}
        infoTitle="Miscellaneous"
        infoText={[
          "Any other costs related to due diligence or closing on the property not included above.",
          "Enter either a fixed dollar amount or a percentage of the purchase price.",
        ]}
        data={acquisitionCostInputs}
        update={update}
      />
    </Stack>
  );
};

export default AcquisitionCostInputs;
