import { Container, Box, Typography } from "@mui/material";

const DisclaimerPrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <div className="disclaimer">
        <h1>Disclaimer and Terms of Use</h1>
        <p>
          By using Easy Pro Forma, you acknolwedge and agree to the following:
        </p>
        <p>
          Use of Easy Pro Forma is not financial advice and should not be relied
          upon as a basis for whether or not to invest in a certain real estate
          asset. Investing in real estate is inherently risky. You should
          consult a real estate professional experienced in your market to
          assist you in assessing the performance of a particular property and
          deciding to buy or sell a specific asset. Any pre-filled values in
          this app are for illustrative purposes only and are to ensure the
          proper functioning of the app, and should not be taken as an accurate
          accounting or estimate of a property's or transaction's actual costs,
          metrics, or performance. You should insert actual numbers specific to
          your transaction or property for the most accurate results. Any
          explanations of the investment metrics in this app are described in a
          very general sense to help users better understand and use the app.
          Different users may have different equations or definitions for these
          metrics. You should conduct your own research as to the definition and
          calculation of any metric for which you are unfamiliar to use the
          definition or calculation that best suits your specific needs.
        </p>
        <p>
          Easy Pro Forma accepts no liability and will not be liable for any
          claim, loss, costs, or damages arising from your use of this site, or
          arising from any transaction or property for which Easy Pro Forma may
          have been used as a tool for making any decision or assumption about
          that transaction or property.
        </p>
      </div>

      <div className="privacy">
        <h1>Privacy Policy</h1>
        <p>
          Thank you for choosing to use Easy Pro Forma ("Company", "we", "us",
          "our"). We are committed to protecting your personal information and
          your right to privacy. When you visit our website easyproforma.com
          (the "Website"), and more generally, use any of our services (the
          "Services", which include the Website and the mobile app), we
          appreciate that you are trusting us with your personal information. We
          take your privacy very seriously. In this privacy policy, we seek to
          explain to you what information we collect, how we use it and what
          rights you have in relation to it. If there are any terms in this
          privacy policy that you do not agree with, please discontinue use of
          our Services immediately. This privacy policy applies to all
          information collected through our Services (which, as described above,
          includes our Website and the mobile app), as well as, any related
          services, sales, marketing or events.
        </p>

        <h3>WHAT INFORMATION DO WE COLLECT?</h3>
        <p>
          When you sign up for Easy Pro Forma, you give us certain information
          voluntarily, such as your email address and a chosen password. We use
          this information for authentication purposes to allow you to save your
          preferences and work within the Website to provide an optimum user
          experience.
        </p>
        <p>
          Some information — such as your Internet Protocol (IP) address and/or
          browser and device characteristics — is collected automatically when
          you visit our Website.
        </p>
        <p>
          We automatically collect certain information when you visit, use or
          navigate the Website. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Website and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Website, and for our internal analytics and reporting purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies, including:
        </p>
        <p>
          Log and Usage Data. Log and usage data is service-related, diagnostic,
          usage and performance information our servers automatically collect
          when you access or use our Website and which we record in log files.
          Depending on how you interact with us, this log data may include your
          IP address, device information, browser type and settings and
          information about your activity in the Website (such as the date/time
          stamps associated with your usage, pages and files viewed, searches
          and other actions you take such as which features you use), device
          event information (such as system activity, error reports (sometimes
          called 'crash dumps') and hardware settings).
        </p>
        <p>
          Device Data. We collect device data such as information about your
          computer, phone, tablet or other device you use to access the Website.
          Depending on the device used, this device data may include information
          such as your IP address (or proxy server), device and application
          identification numbers, location, browser type, hardware model
          Internet service provider and/or mobile carrier, operating system and
          system configuration information.
        </p>

        <h3>HOW DO WE USE YOUR INFORMATION?</h3>
        <p>
          We process your information for purposes based on legitimate business
          interests, the fulfillment of our contract with you, compliance with
          our legal obligations, and/or your consent.
        </p>
        <p>
          We use personal information collected via our Website for a variety of
          business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations.
        </p>
        <p>
          We may use your information for other business purposes, such as user
          authentication, data analysis, identifying usage trends (via Google
          Analytics), determining the effectiveness of our promotional campaigns
          and to evaluate and improve our Website, products, marketing and your
          experience. We may use and store this information in aggregated and
          anonymized form so that it is not associated with individual end users
          and does not include personal information. We will not use
          identifiable personal information without your consent.{" "}
        </p>

        <h3>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h3>
        <p>
          We only share information with your consent, to comply with laws, to
          provide you with services, to protect your rights, or to fulfill
          business obligations. We do not sell your information or data.
        </p>
        <p>
          We may process or share your data that we hold based on the following
          legal basis:
        </p>
        <p>
          Consent: We may process your data if you have given us specific
          consent to use your personal information for a specific purpose.
        </p>
        <p>
          Legitimate Interests: We may process your data when it is reasonably
          necessary to achieve our legitimate business interests.
        </p>
        <p>
          Performance of a Contract: Where we have entered into a contract with
          you, we may process your personal information to fulfill the terms of
          our contract.
        </p>
        <p>
          Legal Obligations: We may disclose your information where we are
          legally required to do so in order to comply with applicable law,
          governmental requests, a judicial proceeding, court order, or legal
          process, such as in response to a court order or a subpoena (including
          in response to public authorities to meet national security or law
          enforcement requirements).
        </p>
        <p>
          Vital Interests: We may disclose your information where we believe it
          is necessary to investigate, prevent, or take action regarding
          potential violations of our policies, suspected fraud, situations
          involving potential threats to the safety of any person and illegal
          activities, or as evidence in litigation in which we are involved.
        </p>
        <p>
          Business Transfers: We may share or transfer your information in
          connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </p>

        <h3>COOKIES AND OTHER TRACKING TECHNOLOGIES</h3>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Cookies are small data
          files that are placed on your computer or mobile device when you visit
          a website. Cookies are widely used by website owners in order to make
          their websites work, or to work more efficiently, as well as to
          provide reporting information.
        </p>
        <p>
          Cookies set by the website owner (in this case, Easy Pro Forma) are
          called "first party cookies". Cookies set by parties other than the
          website owner are called "third party cookies". Third party cookies
          enable third party features or functionality to be provided on or
          through the website (e.g. like advertising, interactive content and
          analytics). The parties that set these third party cookies can
          recognize your computer both when it visits the website in question
          and also when it visits certain other websites.
        </p>
        <p>
          We do not currently use first party cookies, but use third party
          cookies for several reasons. Some cookies are required for technical
          reasons in order for our Websites to operate, and we refer to these as
          "essential" or "strictly necessary" cookies. Other cookies also enable
          us to track and target the interests of our users to enhance the
          experience on our Online Properties. Third parties serve cookies
          through our Websites for advertising, analytics and other purposes.
          This is described in more detail below.
        </p>
        <p>
          You have the right to decide whether to accept or reject cookies.
          Essential cookies cannot be rejected as they are strictly necessary to
          provide you with services. You may set or amend your web browser
          controls to accept or refuse cookies. As the means by which you can
          refuse cookies through your web browser controls vary from
          browser-to-browser, you should visit your browser's help menu for more
          information.
        </p>
        <p>
          In addition, most advertising networks offer you a way to opt out of
          targeted advertising. If you would like to find out more information,
          please visit http://www.aboutads.info/choices/ or
          http://www.youronlinechoices.com.
        </p>
        <p>
          Cookies are not the only way to recognize or track visitors to a
          website. We may use other, similar technologies from time to time,
          like web beacons (sometimes called "tracking pixels" or "clear gifs").
          These are tiny graphics files that contain a unique identifier that
          enable us to recognize when someone has visited our Websites or opened
          an e-mail. This allows us, for example, to monitor the traffic
          patterns of users from one page within a website to another, to
          deliver or communicate with cookies, to understand whether you have
          come to the website from an online advertisement displayed on a
          third-party website, to improve site performance, and to measure the
          success of e-mail marketing campaigns. In many instances, these
          technologies are reliant on cookies to function properly, and so
          declining cookies will impair their functioning.
        </p>

        <h3>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h3>
        <p>
          Our servers are located in the United States. If you are accessing our
          Website from outside the United States, please be aware that your
          information may be transferred to, stored, and processed by us in our
          facilities and by those third parties with whom we may share your
          personal information.
        </p>
        <p>
          If you are a resident in the European Economic Area (EEA) or United
          Kingdom (UK), then these countries may not necessarily have data
          protection laws or other similar laws as comprehensive as those in
          your country. We will however take all necessary measures to protect
          your personal information in accordance with this privacy policy and
          applicable law.
        </p>

        <h3>HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy policy, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). When we have no ongoing
          legitimate business need to process your personal information, we will
          either delete or anonymize such information, or, if this is not
          possible (for example, because your personal information has been
          stored in backup archives), then we will securely store your personal
          information and isolate it from any further processing until deletion
          is possible.
        </p>

        <h3>HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
        <p>
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. We use reputable, industry-standard, third-party services
          that utilize certain information for proper website functioning, such
          as AWS and Stripe. However, despite our safeguards and efforts to
          secure your information, no electronic transmission over the Internet
          or information storage technology can be guaranteed to be 100% secure,
          so we cannot promise or guarantee that hackers, cybercriminals, or
          other unauthorized third parties will not be able to defeat our
          security, and improperly collect, access, steal, or modify your
          information. Although we will do our best to protect your personal
          information, transmission of personal information to and from our
          Website is at your own risk. You should only access the Website within
          a secure environment.
        </p>

        <h3>MINORS</h3>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Website, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent’s use of the Website. If we learn that
          personal information from users less than 18 years of age has been
          collected, we will deactivate the account and take reasonable measures
          to promptly delete such data from our records. If you become aware of
          any data we may have collected from children under age 18, please
          contact us at ezproforma@gmail.com.
        </p>

        <h3>WHAT ARE YOUR PRIVACY RIGHTS?</h3>
        <p>
          If you are a resident in the EEA or UK and you believe we are
          unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority.
          You can find their contact details here:
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
        </p>
        <p>
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Website.
        </p>

        <h3>CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          policy.
        </p>

        <h3>CALIFORNIA RESIDENTS</h3>
        <p>
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with the Website, you have the right to request
          removal of unwanted data that you publicly post on the Website. To
          request removal of such data, please contact us using the contact
          information provided below, and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Website, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g. backups, etc.).
        </p>

        <h3>MISC.</h3>
        <p>
          We use Stripe to process payments when you purchase the Easy Pro Forma
          app. All personal and payment information entered on the checkout page
          is collected, stored, and managed by Stripe, not Easy Pro Forma, and
          is subject to Stripe's privacy policy available at
          https://stripe.com/privacy
        </p>
        <p>
          We may update this privacy policy from time to time with or without
          notice to you. The updated version will be effective as soon as it is
          accessible. If you have questions or comments about this policy, you
          may email us at ezproforma@gmail.com.
        </p>
      </div>
    </Container>
  );
};

export default DisclaimerPrivacyPolicy;
