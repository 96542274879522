import { Typography, Container, Box } from "@mui/material";
import CommercialCalculator from "components/Calculators/CommercialCalculator";

const TryOutSection = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ paddingTop: 8, paddingBottom: 8 }}
      id="tryitout"
    >
      <Typography variant="h3" gutterBottom align="center">
        Give it a test run
      </Typography>
      <Typography variant="body1" paragraph align="center">
        When you sign up for Easy Pro Forma, you unlock a world of
        possibilities. You have the freedom to change all the parameters and
        save as many templates as you like, tailoring each one to your specific
        needs. Try it out below, just enter your purchase price and monthly rent
        to discover how it can transform your real estate investment strategy.
      </Typography>

      <CommercialCalculator />
    </Container>
  );
};

export default TryOutSection;
