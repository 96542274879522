import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { router } from "./Router";
import CssBaseline from "@mui/material/CssBaseline";
import ContextWrapper from "ContextWrapper";
import AuthProvider from "auth/AuthProvider";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<CssBaseline />
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<ContextWrapper>
					<RouterProvider router={router} />
				</ContextWrapper>
			</AuthProvider>
		</QueryClientProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
