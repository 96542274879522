import { createContext, useContext } from "react";

export const CalculateContext = createContext(null);
export const UserContext = createContext(null);

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context)
    throw new Error("useUserContext context must be used inside UserProvider");

  return context;
};
