import { useState } from "react";
import { Button, Menu, MenuItem, CircularProgress } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const OptionsMenu = ({
  handleUpdate,
  handleCreate,
  handleDelete,
  isDefault,
  menuIdentifier = "template",
  isLoadingCreate,
  isLoadingUpdate,
  isLoadingDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (event, action) => {
    event.stopPropagation();
    event.preventDefault();
    await action();
    handleClose();
  };

  return (
    <div>
      <Button
        id={`${menuIdentifier}-menu-button`}
        aria-controls={open ? `${menuIdentifier}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <Menu
        id={`${menuIdentifier}-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
        MenuListProps={{
          "aria-labelledby": `${menuIdentifier}-menu-button"`,
        }}
      >
        <MenuItem
          onClick={(event) => handleMenuItemClick(event, handleUpdate)}
          disabled={
            isDefault || isLoadingUpdate || isLoadingCreate || isLoadingDelete
          }
        >
          {isLoadingUpdate ? <CircularProgress size={24} /> : "Save"}
        </MenuItem>
        <MenuItem
          onClick={(event) => handleMenuItemClick(event, handleCreate)}
          disabled={isLoadingUpdate || isLoadingCreate || isLoadingDelete}
        >
          {isLoadingCreate ? <CircularProgress size={24} /> : "Save as new"}
        </MenuItem>
        <MenuItem
          onClick={(event) => handleMenuItemClick(event, handleDelete)}
          disabled={
            isDefault || isLoadingUpdate || isLoadingCreate || isLoadingDelete
          }
        >
          {isLoadingDelete ? <CircularProgress size={24} /> : "Delete"}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default OptionsMenu;
