import React from "react";
import { Box, Stack, Typography } from "@mui/material";

const AuthResentEmail = () => {
	return (
		<Box sx={{ px: 2, py: 3 }}>
			<Stack gap={3}>
				<Typography variant="body1">
					We have resent the email with your temporary password.
				</Typography>

				<Box>
					<Typography variant="body1" sx={{ mb: 0.5 }}>
						Please make sure to check your spam folder.
					</Typography>
				</Box>
			</Stack>
		</Box>
	);
};

export default AuthResentEmail;
