import { authedAxios } from "./axios";

export const create = async (template) => {
	const { data } = await authedAxios.post("/templates", template);
	return data;
};

export const list = async () => {
	const { data } = await authedAxios.get("/templates");
	return data;
};

export const remove = async (id) => {
	const { data } = await authedAxios.delete(`/templates/${id}`);
	return data;
};

export const update = async (template) => {
	const { data } = await authedAxios.patch(
		`/templates/${template.id}`,
		template
	);
	return data;
};
