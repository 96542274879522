import { useState, useEffect } from "react";
import { Button, Dialog, Container, Stack, Box } from "@mui/material";
import AuthLoginForm from "components/auth/AuthLoginForm";
import AuthResetPasswordForm from "components/auth/AuthResetPasswordForm";
import AuthNewPasswordForm from "components/auth/AuthNewPasswordForm";
import AuthResentEmail from "components/auth/AuthResentEmail";
import PurchaseNotification from "components/auth/PurchaseNotification";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import CommercialCalculator from "components/Calculators/CommercialCalculator";
import UserWrapper from "UserWrapper";
import LandingFooter from "./components/Footer";

import Banner from "./components/Banner";
import PricingSection from "./components/Pricing";
import FeaturesSection from "./components/Features";
import TryOutSection from "./components/TryItOut";

const defaultDialogsState = {
  login: false,
  register: false,
  forgotPassword: false,
  verifyCodeForm: false,
  verifyEmailForm: false,
  newPasswordForm: false,
  resentEmail: false,
  purchaseSuccess: false,
};

const Landing = () => {
  const [searchParams] = useSearchParams();
  const [openDialogs, setOpenDialogs] = useState(defaultDialogsState);

  const handleOnLogin = () =>
    setOpenDialogs({ ...defaultDialogsState, login: true });
  const handleOnRegister = () =>
    setOpenDialogs({ ...defaultDialogsState, register: true });
  const handleOnForgotPassword = () =>
    setOpenDialogs({ ...defaultDialogsState, forgotPassword: true });
  const handleOnVerifyCodeForm = () =>
    setOpenDialogs({ ...defaultDialogsState, newPasswordForm: true });
  const handleResetSuccess = () => {
    setOpenDialogs({ ...defaultDialogsState, login: true });
  };
  const handleResentEmail = () =>
    setOpenDialogs({ ...defaultDialogsState, resentEmail: true });
  const handleOnPurchaseSuccess = () =>
    setOpenDialogs({ ...defaultDialogsState, purchaseSuccess: true });

  const handleOnClose = () => setOpenDialogs(defaultDialogsState);

  useEffect(() => {
    if (searchParams.get("purchaseNotification")) {
      handleOnPurchaseSuccess();
    }
  }, [searchParams]);

  return (
    <UserWrapper>
      <div>
        <Stack direction="row" spacing={2} p={2} justifyContent={"flex-end"}>
          {!localStorage.getItem("refresh_token") ? (
            <Button variant="contained" color="primary" onClick={handleOnLogin}>
              Log In
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/home"
            >
              Dashboard
            </Button>
          )}
        </Stack>
        <Banner />
        <FeaturesSection />
        <PricingSection />
        <TryOutSection />
        <Dialog
          fullWidth
          sx={{ maxWidth: 440, m: "auto" }}
          open={openDialogs.login}
          onClose={handleOnClose}
        >
          <AuthLoginForm
            onRegister={handleOnRegister}
            onForgotPassword={handleOnForgotPassword}
          />
        </Dialog>
        <Dialog
          fullWidth
          sx={{ maxWidth: 440, m: "auto" }}
          open={openDialogs.forgotPassword}
          onClose={handleOnClose}
        >
          <AuthResetPasswordForm
            onResetPassword={handleOnVerifyCodeForm}
            onResendEmail={handleResentEmail}
          />
        </Dialog>

        <Dialog
          fullWidth
          sx={{ maxWidth: 440, m: "auto" }}
          open={openDialogs.newPasswordForm}
          onClose={handleOnClose}
        >
          <AuthNewPasswordForm
            handleOnClose={handleOnClose}
            handleResetSuccess={handleResetSuccess}
          />
        </Dialog>
        <Dialog
          fullWidth
          sx={{ maxWidth: 440, m: "auto" }}
          open={openDialogs.resentEmail}
          onClose={handleOnClose}
        >
          <AuthResentEmail />
        </Dialog>
        <Dialog
          fullWidth
          sx={{ maxWidth: 440, m: "auto" }}
          open={openDialogs.purchaseSuccess}
          onClose={handleOnClose}
        >
          <PurchaseNotification />
        </Dialog>
        <LandingFooter />
      </div>
    </UserWrapper>
  );
};
export default Landing;
