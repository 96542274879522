import { useContext, useState } from "react";
import { CalculateContext } from "context";
import { TextField } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import api from "api";
import { useUserContext } from "context";
import OptionsBar from "./OptionBar";

const DEFAULT_CREATE_FORM = {
  name: "",
  type: "commercial",
};

const CreateForm = ({ value, onChange }) => {
  return (
    <TextField
      label="Name"
      variant="standard"
      value={value}
      onChange={onChange}
    />
  );
};

const TemplateOptions = () => {
  const { limitAccess } = useUserContext();
  const {
    acquisitionCostInputs,
    financingTermsInputs,
    operatingExpensesInputs,
    dcfInputs,
    setTemplate,
  } = useContext(CalculateContext);
  const [createFormData, setCreateFormData] = useState(DEFAULT_CREATE_FORM);

  const {
    data: items,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery("templates", api.templates.list, {
    enabled: !limitAccess,
  });

  const handleSelectTemplate = (template) => {
    setTemplate(template);
  };

  const createMutation = useMutation(api.templates.create, {
    onSuccess: (data) => {
      refetch();
      setCreateFormData(DEFAULT_CREATE_FORM);
      setTemplate(data);
    },
    onError: (error) => {
      console.error(error);
      // Handle any errors if needed
    },
  });

  const updateMutation = useMutation(api.templates.update, {
    onSuccess: (data) => {
      console.log("UPDATE DATA", data);
      refetch();
      setTemplate(data);
    },
  });

  const deleteMutation = useMutation(api.templates.remove, {
    onSuccess: (data) => {
      refetch();
      setTemplate({ id: "default" });
    },
  });

  return (
    <OptionsBar
      title="Assumption Template"
      items={items || []}
      handleSelectItem={handleSelectTemplate}
      createFormTitle="Create Template"
      createFormButtonText="Save Template"
      createData={{
        acquisitionCostInputs,
        financingTermsInputs,
        operatingExpensesInputs,
        dcfInputs,
        ...createFormData,
      }}
      updateData={{
        acquisitionCostInputs,
        financingTermsInputs,
        operatingExpensesInputs,
        dcfInputs,
      }}
      createForm={
        <CreateForm
          value={createFormData.name}
          onChange={(event) =>
            setCreateFormData({ ...createFormData, name: event.target.value })
          }
        />
      }
      createMutation={createMutation}
      updateMutation={updateMutation}
      deleteMutation={deleteMutation}
    />
  );
};

export default TemplateOptions;
