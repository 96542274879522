export const PASSWORD_PATTERNS = {
	lowerCase: /[a-z]/,
	upperCase: /[A-Z]/,
	specialCharacter: /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/,
	number: /[0-9]/,
	minLength: /(?=.*(\S\s*){8,}).*/,
	space: /^\S+$/,
};

export const hideEmail = (email) => {
	const [login, domain] = email.split("@");
	return (
		login[0] +
		Array(login.length - 1)
			.fill("*")
			.join("") +
		"@" +
		domain
	);
};
