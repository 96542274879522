import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import { styled } from "@mui/system";
import InfoModal from "./InfoModal";

const DottedLine = styled("div")({
  flexGrow: 1,
  borderBottom: "1px dashed rgba(0, 0, 0, 0.2)",
  margin: "0 16px",
});

const MetricDisplay = ({
  title,
  value,
  percent,
  number,
  small,
  infoTitle,
  infoText,
  colorizeValue,
}) => {
  value = percent ? value * 100 : value;
  const color = colorizeValue
    ? value > 0
      ? "#4CAF50"
      : value < 0
      ? "#FF6060"
      : "black"
    : "black";

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginBottom={2}
    >
      <Typography variant={small ? "body1" : "h6"}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
        >
          <span>{title}</span>
          <InfoModal title={infoTitle} text={infoText} />
        </div>
      </Typography>

      <DottedLine />
      <Typography variant={small ? "body1" : "h5"}>
        {number || percent ? "" : "$"}
        <span style={{ color, fontWeight: colorizeValue ? "bold" : "normal" }}>
          {value
            ? value.toLocaleString(undefined, { maximumFractionDigits: 2 })
            : "-"}
        </span>

        {percent ? "%" : ""}
      </Typography>
    </Box>
  );
};

export default MetricDisplay;
