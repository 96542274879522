import { authedAxios } from "./axios";

export const create = async (template) => {
  const { data } = await authedAxios.post("/properties", template);
  return data;
};

export const list = async () => {
  const { data } = await authedAxios.get("/properties");
  return data;
};

export const remove = async (id) => {
  const { data } = await authedAxios.delete(`/properties/${id}`);
  return data;
};

export const update = async (template) => {
  const { data } = await authedAxios.patch(
    `/properties/${template.id}`,
    template
  );
  return data;
};
