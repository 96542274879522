import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const LandingFooter = () => {
  return (
    <Box
      p={1}
      borderTop={1}
      borderColor={"rgba(0,0,0,0.1)"}
      sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}
    >
      <Link to="mailto:ezproforma@gmail.com?subject=Feedback Submission">Contact</Link>
      <Link to="/disclaimer-privacy-policy">Privacy Policy & Disclaimer</Link>
    </Box>
  );
};

export default LandingFooter;
