import { useContext } from "react";
import MetricDisplay from "./MetricDisplay";
import { CalculateContext } from "context";
import { calculateOutput } from "calculations";
import DisplayCard from "./DisplayCard";
import { Divider, Box } from "@mui/material";

const MainResultsDisplay = () => {
  const { combinedInputs } = useContext(CalculateContext);

  const metrics = calculateOutput(combinedInputs);

  const headerStyle = {
    textAlign: "center",
  }

  return (
    <DisplayCard>
      <h2 style={headerStyle}>Property Metrics</h2>
      <MetricDisplay
        title="Net Operating Income (NOI)"
        value={metrics.netOperatingIncome}
        infoTitle="NOI"
        infoText="Gross Rent - Vacancy - Operating Expenses."
        colorizeValue
      />
      <MetricDisplay
        title="Capitalization Rate (Cap Rate)"
        value={metrics.capitalizationRate}
        percent
        infoTitle="Cap Rate"
        infoText="Net Operating Income / Total Cost of Property. Generally, market rate deals tend to fall roughly in the 4%-10% range, depending on market location and asset type. A lower cap rate generally means the lower the overall return and the 'safer' the investment. A higher cap rate generally means the higher the potential return but the 'riskier' the investment."
        colorizeValue
      />
      <MetricDisplay
        title="Cash-on-Cash Return"
        value={metrics.cashOnCashReturn}
        percent
        infoTitle="Cash-on-Cash"
        infoText="The return on your total, actual cash invested in the deal, or - Before Tax Cash Flow / (Down Payment + Total Acquisition Costs)"
        colorizeValue
      />
      <MetricDisplay
        title="Before Tax Cash Flow"
        value={metrics.beforeTaxCashFlow}
        infoTitle="Before Tax Cash Flow"
        infoText="Net Operating Income - Annual Debt Payment."
        colorizeValue
      />
      <MetricDisplay
        title="Gross Rent Multiplier"
        value={metrics.grossRentMultiplier}
        number
        infoTitle="Gross Rent Multiplier"
        infoText="Purchase Price / Annual Gross Rent."
        colorizeValue
      />
      <Box pt={2} pb={1}>
        <Divider />
      </Box>

      <h2 style={headerStyle}>Additional Metrics</h2>
      <MetricDisplay
        title="Total Cost of Property"
        value={metrics.totalPropertyCost}
        small
      />
      <MetricDisplay
        title="Annual Effective Gross Rent"
        value={metrics.annualRent}
        small
      />
      <MetricDisplay
        title="Total Acquisition Costs"
        value={metrics.totalAcquisitionCosts}
        small
      />
      <MetricDisplay
        title="Monthly Debt Payment"
        value={metrics.monthlyDebtPayment}
        small
      />
      <MetricDisplay
        title="Annual Debt Payment"
        value={metrics.annualDebtPayment}
        small
      />
      <MetricDisplay
        title="Annual Operating Expenses"
        value={metrics.totalPropertyExpenses}
        small
      />
      <MetricDisplay
        title="Price Per Square Foot"
        value={metrics.pricePerSquareFoot}
        small
      />
      <MetricDisplay
        title="Gross Rent per Square Foot"
        value={metrics.rentPerSquareFoot}
        small
      />
    </DisplayCard>
  );
};

export default MainResultsDisplay;
