import { authedAxios } from "./axios";

export const cancel = async (id) => {
	const { data } = await authedAxios.delete(`/subscriptions/${id}`);
	return data;
};

export const renew = async (id) => {
	const { data } = await authedAxios.post(`/subscriptions/${id}/renew`);
	return data;
};
