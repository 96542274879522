import { useContext } from "react";
import { Stack } from "@mui/material";
import CurrencyInput from "../Inputs/CurrencyInput";
import NumberInput from "../Inputs/NumberInput";
import { CalculateContext } from "context";

const CalculatorInput = () => {
  const { propertyInputs, updateProperty } = useContext(CalculateContext);
  return (
    <Stack>
      <CurrencyInput
        label={"Purchase Price"}
        value={propertyInputs.purchasePrice}
        setValue={(value) => updateProperty("purchasePrice", value)}
        symbol={"$"}
      />

      <CurrencyInput
        label={"Monthly Rent"}
        value={propertyInputs.monthlyRent}
        setValue={(value) => updateProperty("monthlyRent", value)}
        symbol={"$"}
        infoTitle="Gross v. Net Rent"
        infoText="This app defaults to gross rent. To account for net rent, simply enter your total monthly net rent amount here, then change each of the operating expenses in the 'Annual Operating Expenses' section below to $0 (or the appropriate amount for your net rent deal; for example, if there are some utility costs not covered by tenants.)."
      />

      <NumberInput
        label={"Square Footage"}
        value={propertyInputs.squareFootage}
        setValue={(value) => updateProperty("squareFootage", value)}
        symbol={"optional"}
      />
    </Stack>
  );
};
export default CalculatorInput;
