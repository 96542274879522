import { createContext, useState } from "react";
import api from "../api";
import { setSession } from "./utils";

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

const AuthProvider = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(
		!!localStorage.getItem("refresh_token")
	);

	// LOGIN
	const login = async (data) => {
		// AccessToken, ExpiresIn, IdToken, RefreshToken, TokenType
		const response = await api.auth.login(data);
		setSession(response?.data);
		setIsAuthenticated(true);
	};

	// LOGOUT
	const logout = async () => {
		const refreshToken = localStorage.getItem("refresh_token");
		setSession(null);
		setIsAuthenticated(false);
		await api.auth.logout({ refreshToken });
	};

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				login,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
