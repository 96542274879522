import React from "react";
import { Box, Stack, Typography } from "@mui/material";

const PurchaseNotification = () => {
	return (
		<Box sx={{ px: 2, py: 3 }}>
			<Stack gap={3}>
				<Typography variant="h5">Thanks for signing up!</Typography>

				<Box>
					<Typography variant="body1" sx={{ mb: 0.5 }}>
						We sent you an email with a temporary password. You will be asked to
						set a new password at your first login. If you don't see the email,
						please check your spam folder.
					</Typography>
				</Box>
			</Stack>
		</Box>
	);
};

export default PurchaseNotification;
