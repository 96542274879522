import { UserContext } from "context";
import api from "api";
import { useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const UserWrapper = ({ children }) => {
	const { isLoading, error, data, refetch } = useQuery("user", api.user.get, {
		enabled: !!localStorage.getItem("refresh_token"),
	});

	if (isLoading) {
		return (
			<Box
				sx={{
					display: "flex",
					height: "100vh",
					width: "100vw",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<CircularProgress />
			</Box>
		);
	}

	if (error) {
		return <div>An error has occurred: {error.message}</div>;
	}

	return (
		<UserContext.Provider
			value={{ user: data, limitAccess: !data?.hasActiveSubscription, refetch }}
		>
			{children}
		</UserContext.Provider>
	);
};

export default UserWrapper;
