import Finance from "financejs";
// const { irr } = require("node-irr");
const finance = new Finance();

const parseInput = (input) => {
  return {
    // Property Details
    purchasePrice: input.purchasePrice,
    monthlyRent: input.monthlyRent,
    squareFootage: input.squareFootage,

    // Acquisition Costs
    titleFees: input.titleFeeIsPercentage
      ? input.titleFeesPercentage * input.purchasePrice
      : input.titleFeesAmount,

    environmentalTestingCost: input.environmentalTestingCostIsPercentage
      ? input.environmentalTestingCostPercentage * input.purchasePrice
      : input.environmentalTestingCostAmount,

    dueDiligenceCost: input.dueDiligenceCostIsPercentage
      ? input.dueDiligenceCostPercentage * input.purchasePrice
      : input.dueDiligenceCostAmount,

    miscellaneousCostsAmount: input.miscellaneousCostsIsPercentage
      ? input.miscellaneousCostsPercentage * input.purchasePrice
      : input.miscellaneousCostsAmount,

    bankFinancingFeesAmount: input.bankFinancingFeesIsPercentage
      ? input.bankFinancingFeesPercentage *
        input.purchasePrice *
        (1 - input.downPaymentPercentage)
      : input.bankFinancingFeesAmount,

    // Financing Terms
    downPaymentPercentage: input.downPaymentPercentage,
    loanInterestRate: input.loanInterestRate,
    loanAmortizationPeriodInYears: input.loanAmortizationPeriodInYears,

    // Operating Expenses
    vacancyRate: input.vacancyRate,
    managementFeeAmount: input.managementFeeIsPercentage
      ? input.managementFeePercentage * input.monthlyRent * 12
      : input.managementFeeAmount,
    propertyTaxAmount: input.propertyTaxIsPercentage
      ? input.propertyTaxPercentage * input.purchasePrice
      : input.propertyTaxAmount,
    insuranceAmount: input.insuranceIsPercentage
      ? input.insurancePercentage * input.monthlyRent * 12
      : input.insuranceAmount,
    utilitiesAmount: input.utilitiesIsPercentage
      ? input.utilitiesPercentage * input.monthlyRent * 12
      : input.utilitiesAmount,
    maintenanceFeeAmount: input.maintenanceFeeIsPercentage
      ? input.maintenanceFeePercentage * input.monthlyRent * 12
      : input.maintenanceFeeAmount,
  };
};

export const calculateOutput = (input) => {
  const inputs = parseInput(input);
  const totalPropertyExpenses = calculateTotalPropertyExpenses(inputs);
  const totalAcquisitionCosts = calculateTotalAcquisitionCosts(inputs);

  const annualRent = inputs.monthlyRent * 12 * (1 - inputs.vacancyRate);
  const netOperatingIncome = annualRent - totalPropertyExpenses;
  const capitalizationRate =
    netOperatingIncome / (inputs.purchasePrice + totalAcquisitionCosts);
  const monthlyDebtPayment = calculateLoanPayment(
    inputs.purchasePrice * (1 - inputs.downPaymentPercentage),
    inputs.loanInterestRate / 12,
    inputs.loanAmortizationPeriodInYears * 12
  );
  const annualDebtPayment = monthlyDebtPayment * 12;

  const beforeTaxCashFlow = netOperatingIncome - annualDebtPayment;
  const cashOnCashReturn =
    beforeTaxCashFlow /
    (inputs.purchasePrice * inputs.downPaymentPercentage +
      totalAcquisitionCosts);
  const grossRentMultiplier = inputs.purchasePrice / (inputs.monthlyRent * 12);

  const output = {
    netOperatingIncome,
    capitalizationRate,
    cashOnCashReturn,
    beforeTaxCashFlow,
    grossRentMultiplier,
    totalPropertyCost: inputs.purchasePrice + totalAcquisitionCosts,
    annualRent,
    totalAcquisitionCosts,
    monthlyDebtPayment,
    annualDebtPayment,
    totalPropertyExpenses,
    pricePerSquareFoot: inputs.purchasePrice / inputs.squareFootage,
    rentPerSquareFoot: annualRent / inputs.squareFootage,
  };

  return output;
};

const calculateTotalPropertyExpenses = (template) => {
  const {
    managementFeeAmount,
    propertyTaxAmount,
    insuranceAmount,
    utilitiesAmount,
    maintenanceFeeAmount,
  } = template;
  return (
    managementFeeAmount +
    propertyTaxAmount +
    insuranceAmount +
    utilitiesAmount +
    maintenanceFeeAmount
  );
};

const calculateTotalAcquisitionCosts = (template) => {
  const {
    titleFees,
    environmentalTestingCost,
    dueDiligenceCost,
    miscellaneousCostsAmount,
    bankFinancingFeesAmount,
  } = template;
  return (
    titleFees +
    environmentalTestingCost +
    dueDiligenceCost +
    miscellaneousCostsAmount +
    bankFinancingFeesAmount
  );
};

// DCF CALCULATIONS

export const dcfRefactor = (propertyInputs, propertyOutputs, dcfInputs) => {
  propertyInputs = parseInput(propertyInputs);
  const inputs = {
    ...propertyInputs,
    ...propertyOutputs,
    ...dcfInputs,
  };

  let {
    purchasePrice,
    monthlyRent,
    downPaymentPercentage,
    loanInterestRate,
    // loanAmortizationPeriodInYears,
    totalPropertyExpenses,
    vacancyRate,
    annualRentIncreaseRate,
    annualOperatingExpenseIncreaseRate,
    landValuePercentage,
    propertyDepreciationPeriodInYears,
    incomeTaxRate,
    capitalGainsTaxRate,
    endingCapitalizationRate,
    costOfSalePercentage,
    afterTaxRequiredRateOfReturn,
    totalAcquisitionCosts,
    numberOfYears = 5,
    annualDebtPayment,
    calculateExtraYear,
  } = inputs;

  let initialInvestmentAmount =
    purchasePrice * downPaymentPercentage + totalAcquisitionCosts;
  const loanAmount = purchasePrice * (1 - downPaymentPercentage);
  let loanPayment = annualDebtPayment;

  const depreciation = calculateDepreciation(
    purchasePrice,
    landValuePercentage,
    propertyDepreciationPeriodInYears
  );
  let remainingLoan = loanAmount;

  const annualCashFlows = [];

  numberOfYears = calculateExtraYear ? numberOfYears + 1 : numberOfYears;

  for (let i = 1; i <= numberOfYears; i++) {
    const annualGrossRent = calculateAnnualGrossRent(
      monthlyRent,
      vacancyRate,
      annualRentIncreaseRate,
      i
    );

    const annualOperatingExpenses = calculateAnnualOperatingExpenses(
      totalPropertyExpenses,
      annualOperatingExpenseIncreaseRate,
      i
    );

    const annualNetOperatingIncome = annualGrossRent - annualOperatingExpenses;

    loanPayment =
      i > propertyInputs.loanAmortizationPeriodInYears ? 0 : annualDebtPayment;

    let { principalAmount, interestAmount } = calculatePrincipalAndInterest(
      propertyInputs.loanAmortizationPeriodInYears + 1 - i,
      loanPayment,
      loanInterestRate * 100
    );

    if (i <= propertyInputs.loanAmortizationPeriodInYears) {
      remainingLoan -= principalAmount;
    } else {
      remainingLoan = 0;
    }

    const taxableIncome = calculateTaxableIncome(
      annualNetOperatingIncome,
      interestAmount,
      depreciation
    );
    const incomeTax = calculateIncomeTax(taxableIncome, incomeTaxRate);
    const beforeTaxCashFlow = annualNetOperatingIncome - loanPayment;
    const afterTaxCashFlow = calculateAfterTaxCashFlow(
      beforeTaxCashFlow,
      incomeTax
    );

    annualCashFlows.push({
      year: i,
      annualGrossRent,
      annualOperatingExpenses,
      annualNetOperatingIncome,
      loanPayment,
      principalAmount,
      interestAmount,
      remainingLoan,
      beforeTaxCashFlow,
      taxableIncome,
      incomeTax,
      afterTaxCashFlow,
    });
  }

  const propertySalesPrice =
    annualCashFlows[numberOfYears - 1].annualNetOperatingIncome /
    endingCapitalizationRate;

  const propertySaleCost = propertySalesPrice * costOfSalePercentage;

  const adjustedBasis = purchasePrice - depreciation * (numberOfYears - 1);

  const gainOnPropertySale =
    propertySalesPrice - adjustedBasis - propertySaleCost;

  const capitalGainsTaxAmount = Math.max(
    0,
    gainOnPropertySale * capitalGainsTaxRate
  );
  const afterTaxReversionValue =
    propertySalesPrice -
    capitalGainsTaxAmount -
    annualCashFlows[numberOfYears - 2]?.remainingLoan;

  const cashFlowsForIrr = [
    initialInvestmentAmount * -1,
    ...annualCashFlows.map((cf) => cf.afterTaxCashFlow),
  ];
  cashFlowsForIrr.pop();
  cashFlowsForIrr[cashFlowsForIrr.length - 1] =
    cashFlowsForIrr[cashFlowsForIrr.length - 1] + afterTaxReversionValue;

  let finalInternalRateOfReturn,
    finalNetPresentValue = null;

  try {
    finalInternalRateOfReturn = finance.IRR(...cashFlowsForIrr) / 100;
  } catch (err) {
    console.log(err);
  }

  try {
    finalNetPresentValue = finance.NPV(
      afterTaxRequiredRateOfReturn * 100,
      ...cashFlowsForIrr
    );
  } catch (err) {
    console.log(err);
  }

  if (calculateExtraYear) {
    annualCashFlows.pop();
  }

  const outputDCF = {
    initialInvestmentAmount,
    annualCashFlows,
    propertySalesPrice,
    propertySaleCost,
    gainOnPropertySale,
    capitalGainsTaxAmount,
    afterTaxReversionValue,
    finalNetPresentValue,
    finalInternalRateOfReturn,
  };

  return outputDCF;
};

const calculateAnnualGrossRent = (
  monthlyRent,
  vacancyRate,
  annualRentIncreaseRate,
  year
) => {
  return (
    monthlyRent *
    12 *
    (1 - vacancyRate) *
    Math.pow(1 + annualRentIncreaseRate, year - 1)
  );
};

const calculateAnnualOperatingExpenses = (
  initialOperatingExpenses,
  annualOperatingExpenseIncreaseRate,
  year
) => {
  return (
    initialOperatingExpenses *
    Math.pow(1 + annualOperatingExpenseIncreaseRate, year - 1)
  );
};

const calculateDepreciation = (
  purchasePrice,
  landValuePercentage,
  propertyDepreciationPeriodInYears
) => {
  return (
    (purchasePrice * (1 - landValuePercentage)) /
    propertyDepreciationPeriodInYears
  );
};

const calculateTaxableIncome = (
  annualNetOperatingIncome,
  interestAmount,
  depreciation
) => {
  return annualNetOperatingIncome - interestAmount - depreciation;
};

const calculateIncomeTax = (taxableIncome, incomeTaxRate) => {
  return Math.max(0, taxableIncome * incomeTaxRate);
};

const calculateAfterTaxCashFlow = (beforeTaxCashFlow, incomeTax) => {
  return beforeTaxCashFlow - incomeTax;
};

const calculateLoanPayment = (principal, rate, numberOfPeriods) => {
  const numerator = rate * Math.pow(1 + rate, numberOfPeriods);
  const denominator = Math.pow(1 + rate, numberOfPeriods) - 1;
  return principal * (numerator / denominator);
};

const calculatePrincipalAndInterest = (
  remainingTerm,
  loanPayment,
  loanInterestRate
) => {
  const principalAmount = finance.PV(
    loanInterestRate,
    loanPayment,
    remainingTerm
  );
  const interestAmount = loanPayment - principalAmount;
  return { principalAmount, interestAmount };
};

// Old Code----------------------------------------------------------

// const calculateMonthlyMortgagePayment = (inputs) => {
// 	const interestRatePerMonth = inputs.loanInterestRate / 12; // Interest rate per month
// 	const numberOfPeriods = inputs.loanAmortizationPeriodInYears * 12; // Number of periods (months)
// 	const presentValue =
// 		inputs.purchasePrice * (1 - inputs.downPaymentPercentage); // Present value
// 	const futureValue = 0; // Future value

// 	let monthlyPayment, presentValueInterestFactor;

// 	if (interestRatePerMonth === 0) {
// 		return -(presentValue + futureValue) / numberOfPeriods;
// 	}

// 	presentValueInterestFactor = Math.pow(
// 		1 + interestRatePerMonth,
// 		numberOfPeriods
// 	);
// 	monthlyPayment =
// 		(interestRatePerMonth / (presentValueInterestFactor - 1)) *
// 		-(presentValue * presentValueInterestFactor + futureValue);

// 	return -Number(monthlyPayment.toFixed(2));
// };

// const pmt = (template) => {
// 	const ir = template.loanInterestRate / 12; //interest rate per month
// 	const np = template.loanAmortizationPeriodInYears * 12; //number of periods (months)
// 	const pv = template.purchasePrice * (1 - template.downPaymentPercentage); //present value
// 	const fv = 0; //future value
// 	// const type = 0

// 	let pmt, pvif;

// 	if (ir === 0) {
// 		return -(pv + fv) / np;
// 	}

// 	pvif = Math.pow(1 + ir, np);
// 	pmt = (ir / (pvif - 1)) * -(pv * pvif + fv);

// 	// if (type === 1) {
// 	//   pmt /= (1 + ir);
// 	// }

// 	return -Number(pmt.toFixed(2));
// };

// let monthlyDebtPayment = calculateMonthlyMortgagePayment(inputs);
// const monthlyDebtPayment =
// 	calculateLoanPayment(
// 		inputs.purchasePrice * (1 - inputs.downPaymentPercentage),
// 		inputs.loanInterestRate,
// 		inputs.loanAmortizationPeriodInYears
// 	) / 12;
// const annualDebtPayment = monthlyDebtPayment * 12;

//Refactor 3----------------------------------------------------------
// export const calculateOutputdcf3 = (
// 	propertyInputs,
// 	propertyOutputs,
// 	dcfInputs
// ) => {
// 	propertyInputs = parseInput(propertyInputs);

// 	const userInputs = {
// 		purchasePrice: propertyInputs.purchasePrice,
// 		vacancyRate: propertyInputs.vacancyRate,
// 		interestRate: propertyInputs.interestRate,
// 		downPmt:
// 			propertyInputs.purchasePrice * propertyInputs.downPaymentPercentage,
// 		numOfPmts: propertyInputs.amortizationPeriod,
// 	};

// 	const exportCalculations = {
// 		opEx: propertyOutputs.totalPropertyExpenses,
// 		annualGrossRent: propertyOutputs.annualRent,
// 		monthlyMortgage: propertyOutputs.monthlyDebtPayment,
// 		annualMortgage: propertyOutputs.annualDebtPayment,
// 		noi: propertyOutputs.netOperatingIncome,
// 		btcf: propertyOutputs.beforeTaxCashFlow,
// 		totalInv: propertyOutputs.totalAcquisitionCosts + userInputs.downPmt,
// 	};

// 	const inputdcf = {
// 		...userInputs,
// 		...exportCalculations,
// 		...dcfInputs,
// 	};

// 	const depreciation =
// 		(inputdcf.purchasePrice -
// 			inputdcf.purchasePrice * inputdcf.landValuePercent) /
// 		inputdcf.depreciationPeriod;

// 	const calculateAnnualGrossRent = (previousAnnualGrossRent) =>
// 		previousAnnualGrossRent * (1 + inputdcf.rentIncrease);
// 	const calculateOpEx = (previousOpEx) =>
// 		previousOpEx * (1 + inputdcf.opExIncrease);
// 	const calculateNoi = (annualGrossRent, opEx) => annualGrossRent - opEx;
// 	const calculatePrincipalAmt = (remainingPmts) =>
// 		finance.PV(
// 			inputdcf.interestRate * 100,
// 			inputdcf.annualMortgage,
// 			remainingPmts
// 		);
// 	const calculateTaxableIncome = (noi, depreciation, interestAmt) =>
// 		noi - depreciation - interestAmt;
// 	const calculateIncomeTax = (taxableIncome) =>
// 		Math.max(0, taxableIncome * inputdcf.incomeTaxRate);
// 	const calculateBeforeTaxCashFlow = (noi) => noi - inputdcf.annualMortgage;
// 	const calculateAfterTaxCashFlow = (beforeTaxCashFlow, incomeTax) =>
// 		beforeTaxCashFlow - incomeTax;

// 	const results = [];
// 	results.push({
// 		initialInvestment: -Math.abs(inputdcf.totalInv),
// 	});

// 	for (let i = 1; i <= 5; i++) {
// 		const prevResult = results[i - 1];
// 		const annualGrossRent =
// 			i === 1
// 				? inputdcf.annualGrossRent
// 				: calculateAnnualGrossRent(prevResult.annualGrossRent);
// 		const opEx = i === 1 ? inputdcf.opEx : calculateOpEx(prevResult.opEx);
// 		const noi = calculateNoi(annualGrossRent, opEx);
// 		const principalAmt = calculatePrincipalAmt(inputdcf.numOfPmts - i + 1);
// 		const interestAmt = inputdcf.annualMortgage - principalAmt;
// 		const taxableIncome = calculateTaxableIncome(
// 			noi,
// 			depreciation,
// 			interestAmt
// 		);
// 		const incomeTax = calculateIncomeTax(taxableIncome);
// 		const beforeTaxCashFlow =
// 			i === 1 ? inputdcf.btcf : calculateBeforeTaxCashFlow(noi);
// 		const afterTaxCashFlow = calculateAfterTaxCashFlow(
// 			beforeTaxCashFlow,
// 			incomeTax
// 		);

// 		results.push({
// 			year: i,
// 			annualGrossRent,
// 			opEx,
// 			noi,
// 			principalAmt,
// 			interestAmt,
// 			taxableIncome,
// 			incomeTax,
// 			beforeTaxCashFlow,
// 			afterTaxCashFlow,
// 		});
// 	}

// 	const adjustedBasis = inputdcf.purchasePrice - depreciation * 4;
// 	const salesPrice = results[5].noi / inputdcf.endCapRate;
// 	const saleCost = salesPrice * inputdcf.costOfSale;
// 	const gainOnSale = salesPrice - adjustedBasis - saleCost;
// 	const capGainsTax = Math.max(0, gainOnSale * inputdcf.capGainsRate);
// 	const afterTaxReversion =
// 		salesPrice -
// 		capGainsTax -
// 		(inputdcf.purchasePrice -
// 			inputdcf.downPmt -
// 			(results[1].principalAmt +
// 				results[2].principalAmt +
// 				results[3].principalAmt +
// 				results[4].principalAmt));

// 	const cashFlows = [
// 		results[0].initialInvestment,
// 		...results.slice(1).map((result) => result.afterTaxCashFlow),
// 	];
// 	cashFlows[4] += afterTaxReversion;

// 	const finalNPV = finance.NPV(inputdcf.afterTaxReqRor * 100, ...cashFlows);
// 	const finalIRR = irr(cashFlows);

// 	const outputdcf = {
// 		initialInvestment: results[0].initialInvestment,
// 		cashFlows: results.slice(1),
// 		salesPrice,
// 		saleCost,
// 		gainOnSale,
// 		capGainsTax,
// 		afterTaxReversion,
// 		finalNPV,
// 		finalIRR,
// 	};

// 	return outputdcf;
// };

// export const calculateDCF = (inputs, dcfDefaults) => {
// 	inputs = parseInput(inputs);
// 	const {
// 		purchasePrice,
// 		monthlyRent,
// 		squareFootage,
// 		title,
// 		envTesting,
// 		dueDiligence,
// 		misc,
// 		bankFees,
// 		downPaymentPercentage,
// 		interestRate,
// 		amortizationPeriod,
// 		vacancyRate,
// 		managementFee,
// 		propertyTaxes,
// 		insurance,
// 		utilities,
// 		maintenance,
// 	} = inputs;

// 	const {
// 		rentIncrease,
// 		opExIncrease,
// 		depreciationPeriod,
// 		incomeTaxRate,
// 		capGainsRate,
// 		endCapRate,
// 		costOfSale,
// 		afterTaxReqRor,
// 	} = dcfDefaults;

// 	const annualRent = monthlyRent * 12;
// 	const totalAcquisitionCosts =
// 		title + envTesting + dueDiligence + misc + bankFees;
// 	const downPayment = purchasePrice * downPaymentPercentage;
// 	const loanAmount = purchasePrice - downPayment;
// 	const annualDebtPayment =
// 		loanAmount *
// 		(interestRate / (1 - Math.pow(1 + interestRate, -amortizationPeriod * 12)));

// 	const initialInvestment = -1 * (totalAcquisitionCosts + downPayment);

// 	const cashFlows = [];
// 	let currentValue = purchasePrice;

// 	for (let year = 1; year <= 5; year++) {
// 		const annualGrossRent = annualRent * Math.pow(1 + rentIncrease, year - 1);
// 		const opEx =
// 			(managementFee + propertyTaxes + insurance + utilities + maintenance) *
// 			Math.pow(1 + opExIncrease, year - 1);
// 		const noi = annualGrossRent * (1 - vacancyRate) - opEx;

// 		currentValue *= 1 + rentIncrease;
// 		const principalAmt = currentValue * downPaymentPercentage;
// 		const interestAmt = annualDebtPayment - principalAmt;
// 		const taxableIncome =
// 			noi - purchasePrice / depreciationPeriod - interestAmt;
// 		const incomeTax = Math.max(0, taxableIncome * incomeTaxRate);
// 		const beforeTaxCashFlow = noi - annualDebtPayment;
// 		const afterTaxCashFlow = beforeTaxCashFlow - incomeTax;

// 		cashFlows.push({
// 			year,
// 			annualGrossRent,
// 			opEx,
// 			noi,
// 			principalAmt,
// 			interestAmt,
// 			taxableIncome,
// 			incomeTax,
// 			beforeTaxCashFlow,
// 			afterTaxCashFlow,
// 		});
// 	}

// 	const salesPrice = currentValue * endCapRate;
// 	const saleCost = salesPrice * costOfSale;
// 	const gainOnSale = salesPrice - (purchasePrice + totalAcquisitionCosts);
// 	const capGainsTax = Math.max(0, gainOnSale * capGainsRate);
// 	const afterTaxReversion = salesPrice - saleCost - capGainsTax;

// 	const finalNPV = cashFlows.reduce(
// 		(acc, cf) =>
// 			acc + cf.afterTaxCashFlow / Math.pow(1 + afterTaxReqRor, cf.year),
// 		initialInvestment + afterTaxReversion
// 	);
// 	const finalIRR =
// 		-1 +
// 		Math.pow(
// 			(-1 * initialInvestment) /
// 				cashFlows.reduce((acc, cf) => acc + cf.afterTaxCashFlow, 0),
// 			1 / 5
// 		);

// 	return {
// 		initialInvestment,
// 		cashFlows,
// 		salesPrice,
// 		saleCost,
// 		gainOnSale,
// 		capGainsTax,
// 		afterTaxReversion,
// 		finalNPV,
// 		finalIRR,
// 	};
// };

// //////////////////////////
// export const calculateDCF2 = (inputs, dcfDefaults) => {
// 	inputs = parseInput(inputs);
// 	const {
// 		purchasePrice,
// 		monthlyRent,
// 		squareFootage,
// 		title,
// 		envTesting,
// 		dueDiligence,
// 		misc,
// 		bankFees,
// 		downPaymentPercentage,
// 		interestRate,
// 		amortizationPeriod,
// 		vacancyRate,
// 		managementFee,
// 		propertyTaxes,
// 		insurance,
// 		utilities,
// 		maintenance,
// 	} = inputs;

// 	const {
// 		rentIncrease,
// 		opExIncrease,
// 		depreciationPeriod,
// 		incomeTaxRate,
// 		capGainsRate,
// 		endCapRate,
// 		costOfSale,
// 		afterTaxReqRor,
// 	} = dcfDefaults;

// 	const annualRent = monthlyRent * 12;
// 	const totalAcquisitionCosts =
// 		title + envTesting + dueDiligence + misc + bankFees;
// 	const downPayment = purchasePrice * downPaymentPercentage;
// 	const loanAmount = purchasePrice - downPayment;
// 	const annualDebtPayment =
// 		loanAmount *
// 		(interestRate / (1 - Math.pow(1 + interestRate, -amortizationPeriod * 12)));

// 	const initialInvestment = -1 * (totalAcquisitionCosts + downPayment);

// 	const cashFlows = [];

// 	for (let year = 1; year <= 5; year++) {
// 		const annualGrossRent = annualRent * Math.pow(1 + rentIncrease, year - 1);
// 		const opEx =
// 			(managementFee + propertyTaxes + insurance + utilities + maintenance) *
// 			Math.pow(1 + opExIncrease, year - 1);
// 		const noi = annualGrossRent * (1 - vacancyRate) - opEx;

// 		const remainingBalance =
// 			loanAmount * Math.pow(1 + interestRate, year * 12) -
// 			(annualDebtPayment / interestRate) *
// 				(Math.pow(1 + interestRate, year * 12) - 1);
// 		const principalAmt = loanAmount - remainingBalance;
// 		const interestAmt = annualDebtPayment - principalAmt;
// 		const taxableIncome =
// 			noi - purchasePrice / depreciationPeriod - interestAmt;
// 		const incomeTax = Math.max(0, taxableIncome * incomeTaxRate);
// 		const beforeTaxCashFlow = noi - annualDebtPayment;
// 		const afterTaxCashFlow = beforeTaxCashFlow - incomeTax;

// 		cashFlows.push({
// 			year,
// 			annualGrossRent,
// 			opEx,
// 			noi,
// 			principalAmt,
// 			interestAmt,
// 			taxableIncome,
// 			incomeTax,
// 			beforeTaxCashFlow,
// 			afterTaxCashFlow,
// 		});
// 	}

// 	const salesPrice = cashFlows[cashFlows.length - 1].noi / endCapRate;
// 	const saleCost = salesPrice * costOfSale;
// 	const gainOnSale = salesPrice - (purchasePrice + totalAcquisitionCosts);
// 	const capGainsTax = Math.max(0, gainOnSale * capGainsRate);
// 	const afterTaxReversion = salesPrice - saleCost - capGainsTax;

// 	const allCashFlows = [
// 		initialInvestment,
// 		...cashFlows.map((cf) => cf.afterTaxCashFlow),
// 		afterTaxReversion,
// 	];

// 	let npv = 0;
// 	let irr = -1;
// 	let step = 0.05;

// 	while (step >= 1e-6) {
// 		let currentNPV = 0;

// 		for (let i = 0; i < allCashFlows.length; i++) {
// 			currentNPV += allCashFlows[i] / Math.pow(1 + irr, i);
// 		}

// 		if (currentNPV > npv) {
// 			npv = currentNPV;
// 			irr += step;
// 		} else {
// 			step /= 10;
// 		}
// 	}

// 	return {
// 		initialInvestment,
// 		cashFlows,
// 		salesPrice,
// 		saleCost,
// 		gainOnSale,
// 		capGainsTax,
// 		afterTaxReversion,
// 		finalNPV: npv,
// 		finalIRR: irr,
// 	};
// };

// export const calculateCashFlow = (propertyInputs) => {
// 	const {
// 		purchasePrice,
// 		downPayment,
// 		loanInterestRate,
// 		loanTerm,
// 		closingCosts,
// 		rehabCosts,
// 		monthlyRentalIncome,
// 		annualRentGrowthRate,
// 		annualPropertyAppreciationRate,
// 		propertyManagementFee,
// 		maintenanceReserve,
// 		annualPropertyTaxRate,
// 		monthlyInsuranceCost,
// 		hoaFees,
// 		utilityCosts,
// 		vacancyRate,
// 		otherMonthlyExpenses,
// 	} = propertyInputs;

// 	const monthlyInterestRate = loanInterestRate / 100 / 12;
// 	const numberOfPayments = loanTerm * 12;
// 	const downPaymentAmount = (purchasePrice * downPayment) / 100;

// 	const loanAmount = purchasePrice - downPaymentAmount;
// 	const mortgagePayment =
// 		(loanAmount *
// 			monthlyInterestRate *
// 			Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
// 		(Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

// 	const propertyTax = (purchasePrice * (annualPropertyTaxRate / 100)) / 12;
// 	const propertyManagementCost =
// 		monthlyRentalIncome * (propertyManagementFee / 100);
// 	const maintenanceCost = monthlyRentalIncome * (maintenanceReserve / 100);
// 	const vacancyLoss = monthlyRentalIncome * (vacancyRate / 100);

// 	const totalExpenses =
// 		mortgagePayment +
// 		propertyTax +
// 		monthlyInsuranceCost +
// 		hoaFees +
// 		utilityCosts +
// 		propertyManagementCost +
// 		maintenanceCost +
// 		vacancyLoss +
// 		otherMonthlyExpenses;

// 	const cashFlow = monthlyRentalIncome - totalExpenses;

// 	return cashFlow;
// };

// export const calculateROI = (propertyInputs, cashFlow) => {
// 	const { purchasePrice, downPayment, closingCosts, rehabCosts } =
// 		propertyInputs;

// 	const downPaymentAmount = (purchasePrice * downPayment) / 100;
// 	const closingCostsAmount = (purchasePrice * closingCosts) / 100;
// 	const totalInvestment = downPaymentAmount + closingCostsAmount + rehabCosts;

// 	const annualCashFlow = cashFlow * 12;

// 	const roi = (annualCashFlow / totalInvestment) * 100;

// 	return roi;
// };

// export const calculateCapRate = (propertyInputs, cashFlow) => {
// 	const { purchasePrice, loanInterestRate, loanTerm } = propertyInputs;

// 	const monthlyInterestRate = loanInterestRate / 100 / 12;
// 	const numberOfPayments = loanTerm * 12;

// 	const mortgagePayment =
// 		(purchasePrice *
// 			monthlyInterestRate *
// 			Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
// 		(Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

// 	const netOperatingIncome = (cashFlow + mortgagePayment) * 12;

// 	const capRate = (netOperatingIncome / purchasePrice) * 100;

// 	return capRate;
// };

// export const calculateCashOnCashReturn = (propertyInputs, cashFlow) => {
// 	const { purchasePrice, downPayment, closingCosts, rehabCosts } =
// 		propertyInputs;

// 	const downPaymentAmount = (purchasePrice * downPayment) / 100;
// 	const closingCostsAmount = (purchasePrice * closingCosts) / 100;
// 	const totalCashInvested = downPaymentAmount + closingCostsAmount + rehabCosts;

// 	const annualCashFlow = cashFlow * 12;

// 	const cashOnCashReturn = (annualCashFlow / totalCashInvested) * 100;

// 	return cashOnCashReturn;
// };

// export const calculateIRR = (propertyInputs, cashFlow) => {
// 	const {
// 		purchasePrice,
// 		downPayment,
// 		closingCosts,
// 		rehabCosts,
// 		annualPropertyAppreciationRate,
// 		holdingPeriod,
// 	} = propertyInputs;

// 	// return 0;

// 	const downPaymentAmount = (purchasePrice * downPayment) / 100;
// 	const closingCostsAmount = (purchasePrice * closingCosts) / 100;
// 	const initialInvestment = -(
// 		downPaymentAmount +
// 		closingCostsAmount +
// 		rehabCosts
// 	);

// 	const futureValue =
// 		purchasePrice *
// 		Math.pow(1 + annualPropertyAppreciationRate / 100, holdingPeriod);
// 	const saleProceeds = futureValue - initialInvestment;

// 	const annualCashFlow = cashFlow * 12;
// 	const cashFlows = [initialInvestment];

// 	for (let i = 1; i <= holdingPeriod; i++) {
// 		if (i === holdingPeriod) {
// 			cashFlows.push(annualCashFlow + saleProceeds);
// 		} else {
// 			cashFlows.push(annualCashFlow);
// 		}
// 	}

// 	const finance = new Finance();
// 	const irr = finance.IRR(...cashFlows);

// 	return irr;
// };

// export const calculateAllMetrics = (propertyInputs) => {
// 	const cashFlow = calculateCashFlow(propertyInputs);
// 	const roi = calculateROI(propertyInputs, cashFlow);
// 	const capRate = calculateCapRate(propertyInputs, cashFlow);
// 	const cashOnCashReturn = calculateCashOnCashReturn(propertyInputs, cashFlow);
// 	const irr = calculateIRR(propertyInputs, cashFlow);

// 	return {
// 		cashFlow,
// 		roi,
// 		capRate,
// 		cashOnCashReturn,
// 		irr,
// 	};
// };

//////////////////////////////

//Original ----------------------------------------------------------

// export const calculateOutputdcf = (
// 	propertyInputs,
// 	propertyOutputs,
// 	dcfInputs
// ) => {
// 	const userInputs = {
// 		purchasePrice: propertyInputs.purchasePrice,
// 		vacancyRate: propertyInputs.vacancyRate,
// 		interestRate: propertyInputs.interestRate,
// 		downPmt:
// 			propertyInputs.purchasePrice * propertyInputs.downPaymentPercentage,
// 		numOfPmts: propertyInputs.amortizationPeriod,
// 	};

// 	const exportCalculations = {
// 		opEx: propertyOutputs.totalPropertyExpenses,
// 		annualGrossRent: propertyOutputs.annualRent,
// 		monthlyMortgage: propertyOutputs.monthlyDebtPayment,
// 		annualMortgage: propertyOutputs.annualDebtPayment,
// 		noi: propertyOutputs.netOperatingIncome,
// 		btcf: propertyOutputs.beforeTaxCashFlow,
// 		totalInv: propertyOutputs.totalAcquisitionCosts + userInputs.downPmt,
// 	};

// 	const inputdcf = {
// 		...userInputs,
// 		...exportCalculations,
// 		...dcfInputs,
// 	};

// 	const depreciation =
// 		(inputdcf.purchasePrice -
// 			inputdcf.purchasePrice * inputdcf.landValuePercent) /
// 		inputdcf.depreciationPeriod;

// 	const yearZeroCashFlow = -Math.abs(inputdcf.totalInv);
// 	////////////////////
// 	const yearOneNoi = inputdcf.noi;
// 	const yearOneBtcf = inputdcf.btcf;
// 	const principalAmt1 = finance.PV(
// 		inputdcf.interestRate * 100,
// 		inputdcf.annualMortgage,
// 		inputdcf.numOfPmts
// 	);
// 	const interestAmt1 = inputdcf.annualMortgage - principalAmt1;
// 	const taxableIncome1 = inputdcf.noi - depreciation - interestAmt1;
// 	const incomeTax1 = Math.max(0, taxableIncome1 * inputdcf.incomeTaxRate);
// 	const yearOneAfterTaxCashFlow = inputdcf.btcf - incomeTax1;
// 	////////////////////
// 	const annualGrossRentYear2 =
// 		inputdcf.annualGrossRent * (1 + inputdcf.rentIncrease);
// 	const opExYear2 = inputdcf.opEx * (1 + inputdcf.opExIncrease);
// 	const noiYear2 = annualGrossRentYear2 - opExYear2;
// 	const principalAmt2 = finance.PV(
// 		inputdcf.interestRate * 100,
// 		inputdcf.annualMortgage,
// 		inputdcf.numOfPmts - 1
// 	);
// 	const interestAmt2 = inputdcf.annualMortgage - principalAmt2;
// 	const taxableIncome2 = noiYear2 - depreciation - interestAmt2;
// 	const incomeTax2 = Math.max(0, taxableIncome2 * inputdcf.incomeTaxRate);
// 	const beforeTaxCashFlowYear2 = noiYear2 - inputdcf.annualMortgage;
// 	const yearTwoAfterTaxCashFlow = beforeTaxCashFlowYear2 - incomeTax2;
// 	/////////////////
// 	const annualGrossRentYear3 =
// 		annualGrossRentYear2 * (1 + inputdcf.rentIncrease);
// 	const opExYear3 = opExYear2 * (1 + inputdcf.opExIncrease);
// 	const noiYear3 = annualGrossRentYear3 - opExYear3;
// 	const principalAmt3 = finance.PV(
// 		inputdcf.interestRate * 100,
// 		inputdcf.annualMortgage,
// 		inputdcf.numOfPmts - 2
// 	);
// 	const interestAmt3 = inputdcf.annualMortgage - principalAmt3;
// 	const taxableIncome3 = noiYear3 - depreciation - interestAmt3;
// 	const incomeTax3 = Math.max(0, taxableIncome3 * inputdcf.incomeTaxRate);
// 	const beforeTaxCashFlowYear3 = noiYear3 - inputdcf.annualMortgage;
// 	const yearThreeAfterTaxCashFlow = beforeTaxCashFlowYear3 - incomeTax3;
// 	////////////////
// 	const annualGrossRentYear4 =
// 		annualGrossRentYear3 * (1 + inputdcf.rentIncrease);
// 	const opExYear4 = opExYear3 * (1 + inputdcf.opExIncrease);
// 	const noiYear4 = annualGrossRentYear4 - opExYear4;
// 	const principalAmt4 = finance.PV(
// 		inputdcf.interestRate * 100,
// 		inputdcf.annualMortgage,
// 		inputdcf.numOfPmts - 3
// 	);
// 	const interestAmt4 = inputdcf.annualMortgage - principalAmt4;
// 	const taxableIncome4 = noiYear4 - depreciation - interestAmt4;
// 	const incomeTax4 = Math.max(0, taxableIncome4 * inputdcf.incomeTaxRate);
// 	const beforeTaxCashFlowYear4 = noiYear4 - inputdcf.annualMortgage;
// 	const yearFourAfterTaxCashFlow = beforeTaxCashFlowYear4 - incomeTax4;
// 	///////////////
// 	const annualGrossRentYear5 =
// 		annualGrossRentYear4 * (1 + inputdcf.rentIncrease);
// 	const opExYear5 = opExYear4 * (1 + inputdcf.opExIncrease);
// 	const noiYear5 = annualGrossRentYear5 - opExYear5;
// 	///////////////
// 	const adjustedBasis = inputdcf.purchasePrice - depreciation * 4;
// 	const salesPrice = noiYear5 / inputdcf.endCapRate;
// 	const saleCost = salesPrice * inputdcf.costOfSale;
// 	const gainOnSale = salesPrice - adjustedBasis - saleCost;
// 	const capGainsTax = Math.max(0, gainOnSale * inputdcf.capGainsRate);
// 	const afterTaxReversion =
// 		salesPrice -
// 		capGainsTax -
// 		(inputdcf.purchasePrice -
// 			inputdcf.downPmt -
// 			(principalAmt1 + principalAmt2 + principalAmt3 + principalAmt4));
// 	const yearFourTotalCashFlow = yearFourAfterTaxCashFlow + afterTaxReversion;
// 	///////////////
// 	const cashFlows = [
// 		yearZeroCashFlow,
// 		yearOneAfterTaxCashFlow,
// 		yearTwoAfterTaxCashFlow,
// 		yearThreeAfterTaxCashFlow,
// 		yearFourTotalCashFlow,
// 	];
// 	///////////////
// 	const calcFinalNPV = () => {
// 		return finance.NPV(
// 			inputdcf.afterTaxReqRor * 100,
// 			cashFlows[0],
// 			cashFlows[1],
// 			cashFlows[2],
// 			cashFlows[3],
// 			cashFlows[4]
// 		);
// 	};
// 	const calcFinalIRR = () => {
// 		return irr(cashFlows);
// 	};
// 	const finalNPV = calcFinalNPV();
// 	const finalIRR = calcFinalIRR();

// 	const outputdcf = {
// 		yearZeroCashFlow,
// 		yearOneNoi,
// 		yearOneBtcf,
// 		yearOneAfterTaxCashFlow,
// 		noiYear2,
// 		beforeTaxCashFlowYear2,
// 		yearTwoAfterTaxCashFlow,
// 		noiYear3,
// 		beforeTaxCashFlowYear3,
// 		yearThreeAfterTaxCashFlow,
// 		noiYear4,
// 		beforeTaxCashFlowYear4,
// 		yearFourAfterTaxCashFlow,
// 		noiYear5,
// 		salesPrice,
// 		saleCost,
// 		gainOnSale,
// 		capGainsTax,
// 		afterTaxReversion,
// 		finalNPV,
// 		finalIRR,
// 	};

// 	return outputdcf;
// };

//Refactor 2----------------------------------------------------------

// const calculateDepreciation = (
// 	purchasePrice,
// 	landValuePercent,
// 	depreciationPeriod
// ) => (purchasePrice - purchasePrice * landValuePercent) / depreciationPeriod;

// const calculateYearCashFlows = (inputdcf, depreciation) => {
// 	let cashFlows = [-Math.abs(inputdcf.totalInv)];

// 	for (let i = 1; i <= 4; i++) {
// 		const annualGrossRent =
// 			inputdcf.annualGrossRent * (1 + inputdcf.rentIncrease) ** (i - 1);
// 		const opEx = inputdcf.opEx * (1 + inputdcf.opExIncrease) ** (i - 1);
// 		const noi = annualGrossRent - opEx;

// 		const principalAmt = finance.PV(
// 			inputdcf.interestRate * 100,
// 			inputdcf.annualMortgage,
// 			inputdcf.numOfPmts - (i - 1)
// 		);
// 		const interestAmt = inputdcf.annualMortgage - principalAmt;
// 		const taxableIncome = noi - depreciation - interestAmt;

// 		const incomeTax = Math.max(0, taxableIncome * inputdcf.incomeTaxRate);
// 		const beforeTaxCashFlow = noi - inputdcf.annualMortgage;
// 		const afterTaxCashFlow = beforeTaxCashFlow - incomeTax;

// 		if (i === 4) {
// 			const adjustedBasis = inputdcf.purchasePrice - depreciation * (i - 1);
// 			const salesPrice = noi / inputdcf.endCapRate;
// 			const saleCost = salesPrice * inputdcf.costOfSale;
// 			const gainOnSale = salesPrice - adjustedBasis - saleCost;
// 			const capGainsTax = Math.max(0, gainOnSale * inputdcf.capGainsRate);

// 			const principalPaid = cashFlows
// 				.slice(1, i)
// 				.reduce(
// 					(acc, _, j) =>
// 						acc +
// 						finance.PV(
// 							inputdcf.interestRate * 100,
// 							inputdcf.annualMortgage,
// 							inputdcf.numOfPmts - j
// 						),
// 					0
// 				);
// 			const afterTaxReversion =
// 				salesPrice -
// 				capGainsTax -
// 				(inputdcf.purchasePrice - inputdcf.downPmt - principalPaid);
// 			cashFlows.push(afterTaxCashFlow + afterTaxReversion);
// 		} else {
// 			cashFlows.push(afterTaxCashFlow);
// 		}
// 	}

// 	return cashFlows;
// };

// const calculateFinalNPV = (cashFlows, afterTaxReqRor) =>
// 	finance.NPV(afterTaxReqRor * 100, ...cashFlows);

// const calculateFinalIRR = (cashFlows) => irr(cashFlows);

// export const calculateOutputdcf2 = (inputdcf) => {
// 	const depreciation = calculateDepreciation(
// 		inputdcf.purchasePrice,
// 		inputdcf.landValuePercent,
// 		inputdcf.depreciationPeriod
// 	);
// 	const cashFlows = calculateYearCashFlows(inputdcf, depreciation);
// 	const finalNPV = calculateFinalNPV(cashFlows, inputdcf.afterTaxReqRor);
// 	const finalIRR = calculateFinalIRR(cashFlows);

// 	return {
// 		...inputdcf,
// 		depreciation,
// 		cashFlows,
// 		finalNPV,
// 		finalIRR,
// 	};
// };
