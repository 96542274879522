import { CalculateContext } from "context";
import { useState } from "react";
import * as constants from "constants";

const ContextWrapper = ({ children }) => {
  // State
  const [propertyInputs, setPropertyInputs] = useState(
    constants.PROPERTY_INPUTS_DEFAULTS
  );
  const [acquisitionCostInputs, setAcquisitionCostInputs] = useState(
    constants.ACQUISITION_COST_INPUTS_DEFAULTS
  );
  const [financingTermsInputs, setFinancingTermsInputs] = useState(
    constants.FINANCING_TERMS_INPUTS_DEFAULTS
  );
  const [operatingExpensesInputs, setOperatingExpensesInputs] = useState(
    constants.OPERATING_EXPENSES_INPUTS_DEFAULTS
  );
  const [dcfInputs, setDcfInputs] = useState(constants.DCF_INPUTS_DEFAULTS);

  // Updaters
  const updateProperty = (key, value) => {
    setPropertyInputs({ ...propertyInputs, [key]: value });
  };

  const updateMultipleProperty = (updates) => {
    setPropertyInputs({ ...propertyInputs, ...updates });
  };

  const updateAcquisitionCost = (key, value) => {
    setAcquisitionCostInputs({ ...acquisitionCostInputs, [key]: value });
  };

  const updateMultipleAcquisitionCost = (updates) => {
    setAcquisitionCostInputs({ ...acquisitionCostInputs, ...updates });
  };

  const updateFinancingTerms = (key, value) => {
    setFinancingTermsInputs({ ...financingTermsInputs, [key]: value });
  };

  const updateMultipleFinancingTerms = (updates) => {
    setFinancingTermsInputs({ ...financingTermsInputs, ...updates });
  };

  const updateOperatingExpenses = (key, value) => {
    setOperatingExpensesInputs({ ...operatingExpensesInputs, [key]: value });
  };

  const updateMultipleOperatingExpenses = (updates) => {
    setOperatingExpensesInputs({ ...operatingExpensesInputs, ...updates });
  };

  const updateDcf = (key, value) => {
    setDcfInputs({ ...dcfInputs, [key]: value });
  };

  const updateMultipleDcf = (updates) => {
    setDcfInputs({ ...dcfInputs, ...updates });
  };

  // Setters
  const setTemplate = (template) => {
    const isDefault = template.id === "default";
    setAcquisitionCostInputs(
      isDefault
        ? constants.ACQUISITION_COST_INPUTS_DEFAULTS
        : template.acquisitionCostInputs
    );
    setFinancingTermsInputs(
      isDefault
        ? constants.FINANCING_TERMS_INPUTS_DEFAULTS
        : template.financingTermsInputs
    );
    setOperatingExpensesInputs(
      isDefault
        ? constants.OPERATING_EXPENSES_INPUTS_DEFAULTS
        : template.operatingExpensesInputs
    );
    setDcfInputs(
      isDefault ? constants.DCF_INPUTS_DEFAULTS : template.dcfInputs
    );
  };

  const setProperty = (property) => {
    const isDefault = property.id === "default";
    setPropertyInputs(
      isDefault ? constants.PROPERTY_INPUTS_DEFAULTS : property.propertyInputs
    );
  };

  return (
    <>
      <CalculateContext.Provider
        value={{
          // Values
          propertyInputs,
          acquisitionCostInputs,
          financingTermsInputs,
          operatingExpensesInputs,
          dcfInputs,
          combinedInputs: {
            ...propertyInputs,
            ...acquisitionCostInputs,
            ...financingTermsInputs,
            ...operatingExpensesInputs,
            ...dcfInputs,
          },
          // Updaters
          updateProperty,
          updateMultipleProperty,
          updateAcquisitionCost,
          updateMultipleAcquisitionCost,
          updateFinancingTerms,
          updateMultipleFinancingTerms,
          updateOperatingExpenses,
          updateMultipleOperatingExpenses,
          updateDcf,
          updateMultipleDcf,
          // Setters
          setTemplate,
          setProperty,
        }}
      >
        {children}
      </CalculateContext.Provider>
    </>
  );
};

export default ContextWrapper;
