import axios from "axios";
import { setSession } from "auth/utils";
import { refresh } from "./auth";

const unauthedInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

const authedInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

const getAccessToken = async () => {
	const refreshToken = localStorage.getItem("refresh_token");

	if (!refreshToken) {
		return {};
	}

	try {
		const response = await refresh({
			refreshToken,
		});

		const { AccessToken, TokenType } = response.data;

		if (!AccessToken) {
			setSession(null);
		}

		localStorage.setItem("accessToken", AccessToken);

		return { AccessToken, TokenType };
	} catch (error) {
		setSession(null);
	}
};

const authRequestInterceptor = async (config) => {
	const accessToken = localStorage.getItem("accessToken");
	const tokenType = localStorage.getItem("tokenType");

	if (accessToken) {
		config.headers = {
			...config.headers,
			authorization: `${tokenType ?? "Bearer"} ${accessToken}`,
		};
	}

	return config;
};

const authResponseInterceptor = async (error) => {
	const config = error?.config;

	if (error?.response?.status === 401 && !config?.sent) {
		config.sent = true;

		const result = await getAccessToken();

		if (result?.AccessToken) {
			config.headers = {
				...config.headers,
				authorization: `${result?.TokenType ?? "Bearer"} ${
					result?.AccessToken
				}`,
			};
		}

		return axios(config);
	}
	return Promise.reject(error);
};

authedInstance.interceptors.request.use(authRequestInterceptor, (error) =>
	Promise.reject(error)
);
authedInstance.interceptors.response.use(
	(response) => response,
	authResponseInterceptor
);

export const unauthedAxios = unauthedInstance;
export const authedAxios = authedInstance;

const instances = { unauthedAxios, authedAxios };
export default instances;
