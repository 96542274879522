import { Navigate } from "react-router-dom";
import { useAuthContext } from "./useAuthContext";

const AuthGuard = ({ children }) => {
	const { isAuthenticated } = useAuthContext();

	if (!isAuthenticated) {
		return <Navigate replace to="/" />;
	}

	return <>{children}</>;
};

export default AuthGuard;
