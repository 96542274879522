import { Container, Typography, Box, Stack } from "@mui/material";
import { useUserContext } from "context";
import Subscription from "./components/Subscription";
import PriceCard from "components/Payments/PriceCard";

const Account = () => {
  const { user } = useUserContext();

  return (
    <>
      <Container maxWidth="sm">
        <Box pt={3} />
        <Typography variant="h3" component="h1">
          Account
        </Typography>
        <Typography variant="h6" component="h2">
          {user.subscriptions?.length ? "Subscriptions" : "Subscribe"}
        </Typography>
      </Container>
      <Container maxWidth={user.subscriptions?.length ? "sm" : "md"}>
        <Box pt={2}>
          {user.subscriptions?.length ? (
            user.subscriptions.map((sub) => (
              <Subscription key={sub.id} sub={sub} />
            ))
          ) : (
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={4}
              justifyContent="center"
            >
              <PriceCard priceType={"month"} customerEmail={user.email} />
              <PriceCard priceType={"year"} customerEmail={user.email} />
            </Stack>
          )}
        </Box>
      </Container>
    </>
  );
};

export default Account;
