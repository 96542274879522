import { useContext, useState } from "react";
import { CalculateContext } from "context";
import { TextField } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import api from "api";
import { useUserContext } from "context";
import OptionsBar from "./OptionBar";

const DEFAULT_CREATE_FORM = {
  name: "",
};

const CreateForm = ({ value, onChange }) => {
  return (
    <TextField
      label="Name"
      variant="standard"
      value={value}
      onChange={onChange}
    />
  );
};

const PropertyOptions = () => {
  const { limitAccess } = useUserContext();
  const { propertyInputs, setProperty } = useContext(CalculateContext);
  const [createFormData, setCreateFormData] = useState(DEFAULT_CREATE_FORM);

  const {
    data: items,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery("properties", api.properties.list, {
    enabled: !limitAccess,
  });

  const handleSelectProperty = (property) => {
    setProperty(property);
  };

  const createMutation = useMutation(api.properties.create, {
    onSuccess: (data) => {
      refetch();
      setCreateFormData(DEFAULT_CREATE_FORM);
      setProperty(data);
    },
    onError: (error) => {
      console.error(error);
      // Handle any errors if needed
    },
  });

  const updateMutation = useMutation(api.properties.update, {
    onSuccess: (data) => {
      refetch();
      setProperty(data);
    },
  });

  const deleteMutation = useMutation(api.properties.remove, {
    onSuccess: () => {
      refetch();
      setProperty({ id: "default" });
    },
  });

  return (
    <OptionsBar
      title="Property"
      items={items || []}
      handleSelectItem={handleSelectProperty}
      createFormTitle="Create Property"
      createFormButtonText="Save Property"
      createData={{
        propertyInputs,
        ...createFormData,
      }}
      updateData={{
        propertyInputs,
      }}
      createForm={
        <CreateForm
          value={createFormData.name}
          onChange={(event) =>
            setCreateFormData({ ...createFormData, name: event.target.value })
          }
        />
      }
      createMutation={createMutation}
      updateMutation={updateMutation}
      deleteMutation={deleteMutation}
      menuIdentifier="property"
    />
  );
};

export default PropertyOptions;
