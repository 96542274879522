import { createBrowserRouter } from "react-router-dom";

import Layout from "Layouts";
import Landing from "Pages/Landing";
import Home from "Pages/Home";
import Account from "Pages/Account";
import DisclaimerPrivacyPolicy from "Pages/DisclamerPrivacyPolicy";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <Landing />,
	},
	{
		path: "/disclaimer-privacy-policy",
		element: <DisclaimerPrivacyPolicy />,
	},
	{
		path: "/*",
		element: <Layout />,
		children: [
			{ path: "home", element: <Home /> },
			{ path: "account", element: <Account /> },
		],
	},
]);
