import React from "react";
import { Button, Typography, Grid, Container, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import iphoneMockup1 from "../../../assets/images/iphone_mockup_1.png";

const Banner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container
      maxWidth="lg"
      sx={{ paddingTop: theme.spacing(6), paddingBottom: theme.spacing(6) }}
    >
      <Grid container spacing={3} direction={isMobile ? "column" : "row"}>
        <Grid item xs={12} md={6}>
          <Typography
            variant={isMobile ? "h4" : "h2"}
            gutterBottom
            sx={{ textAlign: isMobile ? "center" : "left" }}
          >
            A simple real estate pro forma wherever you need it. On site or on
            the go.
          </Typography>
          {/* <Typography variant="body1" paragraph>
            Brief paragraph text goes here. Describe your app or service in a
            concise and engaging manner.
          </Typography> */}
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: isMobile ? "center" : "flex-start" }}
          >
            <Button variant="contained" color="primary" href="#pricing">
              Start For Free
            </Button>
            <Button variant="outlined" color="primary" href="#tryitout">
              Try it out
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <img
            src={iphoneMockup1}
            alt="Our App"
            style={{ width: "100%", height: "auto" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Banner;
