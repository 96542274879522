import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography, Button } from "@mui/material";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

export default function BasicModal({
	open,
	handleClose,
	children,
	title,
	body,
	cancelButtonText,
	confirmButtonText,
	handleConfirm,
}) {
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					{title && (
						<Typography id="modal-modal-title" variant="h6" component="h2">
							{title}
						</Typography>
					)}
					{body && (
						<Typography id="modal-modal-description" sx={{ mt: 2 }}>
							{body}
						</Typography>
					)}
					<Box>{children}</Box>
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						{cancelButtonText && (
							<Box mr={1}>
								<Button variant="contained" onClick={handleClose}>
									{cancelButtonText}
								</Button>
							</Box>
						)}
						{confirmButtonText && (
							<Button variant="contained" onClick={handleConfirm}>
								{confirmButtonText}
							</Button>
						)}
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
