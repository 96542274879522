import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const InfoModal = ({ title, text }) => {
	const [open, setOpen] = useState(false);
	if (typeof text == "string") {
		text = [text];
	}

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	if (!text) return null;

	return (
		<div>
			<IconButton
				onClick={handleOpen}
				sx={{ padding: 0, paddingLeft: "0.2rem" }}
			>
				<InfoIcon sx={{ fontSize: "1rem" }} />
			</IconButton>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="info-modal-title"
				aria-describedby="info-modal-description"
			>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: 400,
						bgcolor: "background.paper",
						boxShadow: 24,
						p: 4,
					}}
				>
					{title ? (
						<Typography
							id="info-modal-title"
							variant="h6"
							component="h2"
							pb={1}
						>
							{title}
						</Typography>
					) : null}
					{text.map((t, i) => (
						<Typography
							key={title + i}
							variant="body2"
							sx={{ paddingBottom: i === text.length - 1 ? 0 : 1 }}
						>
							{t}
						</Typography>
					))}
				</Box>
			</Modal>
		</div>
	);
};

export default InfoModal;
